import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Slideshow } from "./slideshow"
import { Pattern1Illustration } from "components/library/illustrations/patterns/pattern1";

export const FeaturesOfTheAiEngine = () => {
	const { t } = useTranslation()

	return (
		<section id="features-of-ai" className="flex flex-col gap-12">
			<div className="flex flex-col gap-10 text-center items-center">
				<div className="w-20 h-20 sm:h-28 sm:w-28 rounded-full bg-[#DEE9FD] flex justify-center items-center">
					<Pattern1Illustration className="w-[70%]" />
				</div>
				<h2 className="text-theme-blue text-3xl sm:text-4xl md:text-5xl">
					{t("products-ai-page.features-of-ai-engine.heading")}
				</h2>
			</div>

			<Slideshow />
		</section>
	)
}
