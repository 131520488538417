import React from "react"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"

export const AiForwardPredictionIllustration: Illustration = ({
	className = "",
	...props
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="359"
			height="280"
			fill="none"
			viewBox="0 0 359 280"
			className={mergeClasses({ classes: ["w-40", className] })}
			{...props}
		>
			<path
				fill="#B3CAFF"
				d="M41.482 195.997V22.769C41.482 10.214 54.697 0 70.913 0h240.185c16.216 0 29.432 10.214 29.432 22.769v173.228H41.482z"
				opacity="0.35"
			></path>
			<path
				fill="#63D3FF"
				d="M311.098 193.134H70.913c-12.417 0-22.466-10.049-22.466-22.466V29.431c0-12.417 10.05-22.466 22.466-22.466h240.185c12.417 0 22.466 10.05 22.466 22.466v141.237c0 12.389-10.077 22.466-22.466 22.466z"
				opacity="0.4"
			></path>
			<path
				fill="#77B0F2"
				d="M192.233 38.274l-1.032 1.032 23.42 23.42 1.031-1.032-23.419-23.42zM186.88 45.196l-1.032 1.032 21.843 21.842 1.032-1.032-21.843-21.842zM183.65 53.142l-1.031 1.032 17.151 17.15 1.031-1.031-17.151-17.15zM182.518 61.704l-1.032 1.032 9.695 9.695 1.032-1.032-9.695-9.695zM199.143 32.95l-1.032 1.031 21.843 21.843 1.032-1.032-21.843-21.842zM207.064 29.696l-1.031 1.031 17.151 17.152 1.031-1.032-17.151-17.151zM215.653 28.59l-1.032 1.031 9.695 9.695 1.032-1.032-9.695-9.695z"
			></path>
			<path
				fill="#fff"
				d="M216.39 30.34a1.778 1.778 0 01-2.505 0 1.778 1.778 0 010-2.505 1.778 1.778 0 012.505 0 1.777 1.777 0 010 2.505zM225.41 30.804a1.79 1.79 0 10-3.391-1.142 1.79 1.79 0 003.391 1.142zM184.398 72.05a1.778 1.778 0 01-2.505 0 1.777 1.777 0 010-2.505 1.778 1.778 0 012.505 0 1.779 1.779 0 010 2.505zM208.571 32.24a2.857 2.857 0 01-4.019 0 2.857 2.857 0 010-4.02 2.857 2.857 0 014.019 0c1.129 1.1 1.129 2.89 0 4.02zM201 35.957a3.44 3.44 0 01-4.873 0 3.44 3.44 0 010-4.873 3.44 3.44 0 014.873 0 3.44 3.44 0 010 4.873zM194.282 41.355a3.635 3.635 0 10-5.14-5.14 3.635 3.635 0 005.14 5.14zM188.886 48.098a3.44 3.44 0 01-4.873 0 3.44 3.44 0 010-4.873 3.44 3.44 0 014.873 0 3.44 3.44 0 010 4.873zM185.861 54.557a2.864 2.864 0 10-5.43-1.823 2.864 2.864 0 005.43 1.823zM183.27 63.488c-.688.688-1.817.688-2.533 0-.688-.689-.688-1.818 0-2.533.688-.689 1.817-.689 2.533 0 .688.715.688 1.844 0 2.533z"
			></path>
			<path
				fill="#D9EAFF"
				d="M226.108 40.058a1.778 1.778 0 01-2.505 0 1.779 1.779 0 010-2.505 1.778 1.778 0 012.505 0 1.777 1.777 0 010 2.505zM225.723 49.392a2.859 2.859 0 01-4.02 0 2.859 2.859 0 010-4.02 2.859 2.859 0 014.02 0 2.809 2.809 0 010 4.02zM222.556 58.049a3.441 3.441 0 10-4.313-5.365 3.441 3.441 0 004.313 5.365zM217.711 64.81a3.622 3.622 0 01-5.148 0 3.622 3.622 0 010-5.149 3.622 3.622 0 015.148 0c1.404 1.404 1.404 3.717 0 5.148zM208.269 70.949a3.441 3.441 0 10-.001-6.883 3.441 3.441 0 00.001 6.883zM202.321 72.821a2.842 2.842 0 01-4.047 0 2.843 2.843 0 010-4.047 2.842 2.842 0 014.047 0 2.866 2.866 0 010 4.047zM192.96 73.206c-.688.689-1.817.689-2.533 0-.688-.688-.688-1.817 0-2.533.689-.688 1.817-.688 2.533 0 .716.689.716 1.817 0 2.533z"
			></path>
			<path
				fill="#77B0F2"
				d="M111.039 119.451l-1.032 1.032 23.42 23.419 1.032-1.031-23.42-23.42zM105.725 126.373l-1.032 1.032 21.843 21.842 1.032-1.031-21.843-21.843zM102.476 134.299l-1.032 1.032 17.151 17.151 1.032-1.032-17.151-17.151zM101.343 142.9l-1.031 1.032 9.694 9.695 1.032-1.032-9.695-9.695zM117.969 114.107l-1.032 1.032 21.843 21.842 1.031-1.032-21.842-21.842zM125.91 110.872l-1.032 1.032 17.151 17.151 1.032-1.032-17.151-17.151zM134.479 109.747l-1.032 1.031 9.695 9.695 1.031-1.032-9.694-9.694z"
			></path>
			<path
				fill="#fff"
				d="M135.227 111.53a1.776 1.776 0 01-2.505 0 1.776 1.776 0 010-2.505 1.778 1.778 0 012.505 0 1.778 1.778 0 010 2.505z"
			></path>
			<path
				fill="#D9EAFF"
				d="M143.789 112.659a1.776 1.776 0 01-2.505 0 1.778 1.778 0 010-2.505 1.778 1.778 0 012.505 0c.716.688.716 1.789 0 2.505zM103.208 153.24a1.777 1.777 0 01-2.506 0 1.778 1.778 0 010-2.505 1.78 1.78 0 012.506 0 1.778 1.778 0 010 2.505z"
			></path>
			<path
				fill="#fff"
				d="M127.408 113.403a2.86 2.86 0 01-4.02 0 2.86 2.86 0 010-4.02 2.86 2.86 0 014.02 0 2.86 2.86 0 010 4.02zM113.092 122.543a3.623 3.623 0 01-5.149 0 3.623 3.623 0 010-5.148 3.623 3.623 0 015.149 0 3.623 3.623 0 010 5.148zM107.381 129.56a3.442 3.442 0 10-4.22-5.438 3.442 3.442 0 004.22 5.438zM101.941 137.685a2.863 2.863 0 100-5.726 2.863 2.863 0 000 5.726zM102.107 144.678c-.689.688-1.817.688-2.533 0-.689-.688-.689-1.817 0-2.533.688-.688 1.817-.688 2.533 0 .688.688.688 1.817 0 2.533z"
			></path>
			<path
				fill="#D9EAFF"
				d="M144.918 121.222a1.778 1.778 0 01-2.505 0 1.777 1.777 0 010-2.506 1.778 1.778 0 012.505 0 1.78 1.78 0 010 2.506zM144.56 130.555a2.858 2.858 0 01-4.019 0 2.86 2.86 0 010-4.02 2.858 2.858 0 014.019 0 2.857 2.857 0 010 4.02zM139.247 139.97a3.44 3.44 0 100-6.882 3.44 3.44 0 000 6.882zM137.526 144.191a3.635 3.635 0 10-7.096-1.573 3.635 3.635 0 007.096 1.573zM129.209 151.403a3.44 3.44 0 10-4.22-5.437 3.441 3.441 0 004.22 5.437zM121.131 154.011a2.842 2.842 0 01-4.048 0 2.843 2.843 0 010-4.047 2.842 2.842 0 014.048 0 2.843 2.843 0 010 4.047zM111.798 154.369c-.689.688-1.817.688-2.533 0-.689-.688-.689-1.817 0-2.533.688-.688 1.817-.688 2.533 0 .688.716.688 1.845 0 2.533z"
			></path>
			<path
				fill="#fff"
				d="M119.191 117.621a3.442 3.442 0 10-3.605-5.864 3.442 3.442 0 003.605 5.864z"
			></path>
			<path
				fill="#77B0F2"
				d="M151.626 78.873l-1.032 1.031 23.42 23.42 1.031-1.032-23.419-23.42zM146.312 85.794l-1.032 1.032 21.843 21.842 1.032-1.032-21.843-21.842zM143.063 93.72l-1.032 1.032 17.151 17.151 1.031-1.031-17.15-17.151zM141.931 102.282l-1.032 1.032 9.695 9.695 1.032-1.032-9.695-9.695zM158.556 73.528l-1.032 1.032 21.843 21.843 1.031-1.032-21.842-21.843zM166.497 70.293l-1.032 1.032 17.151 17.15 1.032-1.031-17.151-17.151zM175.066 69.168l-1.032 1.032 9.695 9.695 1.032-1.032-9.695-9.695z"
			></path>
			<path
				fill="#fff"
				d="M174.542 71.472a1.79 1.79 0 100-3.579 1.79 1.79 0 000 3.579zM184.398 72.05a1.778 1.778 0 01-2.505 0 1.777 1.777 0 010-2.505 1.778 1.778 0 012.505 0 1.779 1.779 0 010 2.505z"
			></path>
			<path
				fill="#B1E9FF"
				d="M143.789 112.659a1.776 1.776 0 01-2.505 0 1.778 1.778 0 010-2.505 1.778 1.778 0 012.505 0c.716.688.716 1.789 0 2.505z"
			></path>
			<path
				fill="#fff"
				d="M167.989 72.82a2.858 2.858 0 01-4.019 0 2.857 2.857 0 010-4.019 2.858 2.858 0 014.019 0 2.809 2.809 0 010 4.02zM151.113 83.008a3.634 3.634 0 100-7.269 3.634 3.634 0 000 7.269zM145.854 89.698a3.44 3.44 0 100-6.882 3.44 3.44 0 000 6.882zM144.07 96.664a2.863 2.863 0 10-3.021-4.866 2.863 2.863 0 003.021 4.866zM142.688 104.069c-.688.688-1.817.688-2.533 0s-.688-1.817 0-2.533c.688-.688 1.817-.688 2.533 0 .688.716.688 1.845 0 2.533z"
			></path>
			<path
				fill="#C6F8FF"
				d="M185.499 80.64a1.778 1.778 0 01-2.505 0 1.777 1.777 0 010-2.506 1.778 1.778 0 012.505 0 1.73 1.73 0 010 2.506zM183.132 90.827a2.863 2.863 0 100-5.727 2.863 2.863 0 000 5.727zM179.828 99.389a3.442 3.442 0 100-6.884 3.442 3.442 0 000 6.884zM177.389 105.066a3.634 3.634 0 10-5.714-4.49 3.634 3.634 0 005.714 4.49zM161.399 113.694a2.863 2.863 0 10-3.41-4.601 2.863 2.863 0 003.41 4.601zM152.379 113.788c-.688.688-1.817.688-2.533 0-.688-.688-.688-1.817 0-2.533.689-.688 1.817-.688 2.533 0 .688.688.688 1.817 0 2.533z"
			></path>
			<path
				fill="#fff"
				d="M161.162 75.442a3.441 3.441 0 10-6.367-2.615 3.441 3.441 0 006.367 2.615z"
			></path>
			<path
				fill="#C6F8FF"
				d="M169.297 111.125a3.441 3.441 0 10-3.238-6.073 3.441 3.441 0 003.238 6.073z"
			></path>
			<path
				fill="#77B0F2"
				d="M214.49 134.657h45.4v-2.643h-45.4v2.643zm0-5.837h72.931v-2.643H214.49v2.643zm0-20.208v2.643h72.931v-2.643H214.49zm0 14.344h72.931v-2.643H214.49v2.643zm0-5.837h72.931v-2.643H214.49v2.643zm-7.571-10.682a3.513 3.513 0 00-3.524 3.524 3.513 3.513 0 003.524 3.524 3.513 3.513 0 003.524-3.524c-.027-1.955-1.597-3.524-3.524-3.524zM214.49 177.964h45.4v-2.643h-45.4v2.643zm0-5.837h72.931v-2.643H214.49v2.643zm0-20.208v2.643h72.931v-2.643H214.49zm0 14.344h72.931v-2.643H214.49v2.643zm0-5.864h72.931v-2.643H214.49v2.643zm-7.571-10.682a3.513 3.513 0 00-3.524 3.524 3.513 3.513 0 003.524 3.524 3.513 3.513 0 003.524-3.524c-.027-1.955-1.597-3.524-3.524-3.524z"
			></path>
			<path
				fill="#fff"
				d="M312.732 46.364c7.407-7.408 7.408-19.419 0-26.826-7.408-7.408-19.419-7.408-26.827 0-7.408 7.407-7.408 19.418 0 26.826 7.408 7.408 19.419 7.408 26.827 0z"
			></path>
			<path
				fill="#29ABE2"
				d="M295.516 40.224c-.083 0-.138-.028-.22-.055l-3.8-2.203a.439.439 0 01-.22-.385v-4.378c0-.165.082-.303.22-.385l3.8-2.203a.422.422 0 01.44 0l3.799 2.203a.44.44 0 01.221.385v4.378a.44.44 0 01-.221.385l-3.799 2.203c-.083.027-.165.055-.22.055zm-3.387-2.891l3.359 1.955 3.359-1.955V33.45l-3.359-1.955-3.359 1.955v3.882z"
			></path>
			<path
				fill="#29ABE2"
				d="M303.114 40.224c-.082 0-.137-.028-.22-.055l-3.799-2.203a.439.439 0 01-.22-.385v-4.378c0-.165.082-.303.22-.385l3.799-2.203a.423.423 0 01.441 0l3.799 2.203c.138.082.22.22.22.385v4.378a.439.439 0 01-.22.385l-3.799 2.203c-.083.027-.138.055-.221.055zm-3.358-2.891l3.358 1.955 3.359-1.955V33.45l-3.359-1.955-3.358 1.955v3.882zM295.516 29.293h-3.524a.435.435 0 01-.441-.44c0-.248.193-.44.441-.44h3.524c.248 0 .44.192.44.44-.027.247-.22.44-.44.44zM306.638 29.293h-3.524a.435.435 0 01-.44-.44c0-.248.193-.44.44-.44h3.524c.248 0 .441.192.441.44 0 .247-.193.44-.441.44z"
			></path>
			<path
				fill="#29ABE2"
				d="M299.315 40.967a.434.434 0 01-.44-.44v-4.653c0-.248.192-.44.44-.44s.441.192.441.44v4.652c0 .248-.193.44-.441.44z"
			></path>
			<path
				fill="#D9EAFF"
				d="M299.315 48.758a4.184 4.184 0 100-8.368 4.184 4.184 0 000 8.368z"
			></path>
			<path
				fill="#29ABE2"
				d="M299.315 33.616c-.083 0-.138-.028-.22-.055l-3.8-2.203a.44.44 0 01-.22-.385v-4.378a.44.44 0 01.22-.385l3.8-2.203a.422.422 0 01.44 0l3.8 2.203a.44.44 0 01.22.385v4.378a.44.44 0 01-.22.385l-3.8 2.203c-.082.055-.137.055-.22.055zm-3.386-2.863l3.359 1.954 3.358-1.954V26.87l-3.358-1.954-3.359 1.954v3.883z"
			></path>
			<path
				fill="#fff"
				d="M298.379 43.334c.248 0 .468.055.606.138l.083-.33c-.111-.056-.331-.138-.716-.138-.936 0-1.597.633-1.597 1.597 0 .99.633 1.541 1.514 1.541.358 0 .661-.082.799-.137l-.083-.303a1.529 1.529 0 01-.633.138c-.744 0-1.157-.468-1.157-1.212 0-.853.468-1.294 1.184-1.294zm3.056-.275v1.266h-1.459V43.06h-.385v3.056h.385v-1.432h1.459v1.432h.386v-3.056h-.386z"
			></path>
			<path
				fill="#45628F"
				d="M310.493 32.955a4.185 4.185 0 100-8.37 4.185 4.185 0 000 8.37z"
			></path>
			<path
				fill="#fff"
				d="M309.557 27.532c.248 0 .468.055.606.137l.082-.33c-.11-.055-.33-.138-.716-.138-.936 0-1.596.633-1.596 1.597 0 .991.633 1.542 1.514 1.542.358 0 .661-.083.798-.138l-.082-.303a1.53 1.53 0 01-.634.138c-.743 0-1.156-.468-1.156-1.211 0-.826.468-1.294 1.184-1.294zm3.056-.276v1.267h-1.459v-1.267h-.386v3.056h.386v-1.431h1.459v1.431h.385v-3.056h-.385z"
			></path>
			<path
				fill="#D9EAFF"
				d="M287.78 32.955a4.184 4.184 0 100-8.368 4.184 4.184 0 000 8.368z"
			></path>
			<path
				fill="#fff"
				d="M286.871 27.532c.248 0 .468.055.606.137l.082-.33c-.11-.055-.33-.138-.716-.138-.936 0-1.596.633-1.596 1.597 0 .991.633 1.542 1.514 1.542.358 0 .661-.083.798-.138l-.082-.303a1.533 1.533 0 01-.634.138c-.743 0-1.156-.468-1.156-1.211-.027-.826.468-1.294 1.184-1.294zm3.056-.276v1.267h-1.459v-1.267h-.386v3.056h.386v-1.431h1.459v1.431h.385v-3.056h-.385zM268.466 87.718c6.612-6.612 6.612-17.333 0-23.945-6.612-6.612-17.333-6.612-23.945 0-6.612 6.612-6.612 17.333 0 23.945 6.612 6.612 17.333 6.612 23.945 0z"
			></path>
			<path
				fill="#D9EAFF"
				d="M248.078 81.604a.415.415 0 01-.385-.248c-.083-.22 0-.44.22-.55l8.315-3.635c.22-.083.44 0 .55.22.083.22 0 .44-.22.551l-8.314 3.634c-.055.028-.11.028-.166.028z"
			></path>
			<path
				fill="#D9EAFF"
				d="M261.927 84.384a.443.443 0 01-.303-.137l-5.534-6.388c-.137-.165-.137-.44.028-.578.165-.138.44-.138.578.028l5.534 6.387c.137.165.137.44-.028.578-.11.055-.192.11-.275.11z"
			></path>
			<path
				fill="#D9EAFF"
				d="M256.366 77.97a.416.416 0 01-.386-.248c-.082-.22 0-.44.22-.55l8.287-3.635a.424.424 0 01.551.22c.083.22 0 .441-.22.551l-8.287 3.634c-.055.028-.11.028-.165.028z"
			></path>
			<path
				fill="#D9EAFF"
				d="M256.365 77.97h-.082a.453.453 0 01-.331-.496l2.451-11.838a.452.452 0 01.495-.33c.22.054.358.275.33.495l-2.45 11.838a.41.41 0 01-.413.33z"
			></path>
			<path
				fill="#D9EAFF"
				d="M256.365 77.97a.44.44 0 01-.302-.138l-5.534-6.388c-.138-.165-.138-.44.027-.578.165-.138.441-.138.578.028l5.534 6.387c.138.165.138.44-.027.578a.354.354 0 01-.276.11z"
			></path>
			<path
				fill="#45628F"
				d="M256.366 80.805a3.221 3.221 0 100-6.442 3.221 3.221 0 000 6.442z"
			></path>
			<path
				fill="#D9EAFF"
				d="M250.288 81.74a2.257 2.257 0 10-4.392-1.04 2.257 2.257 0 004.392 1.04z"
			></path>
			<path
				fill="#29ABE2"
				d="M260.399 67.315a2.257 2.257 0 10-3.19-3.193 2.257 2.257 0 003.19 3.193zM263.522 85.557a2.257 2.257 0 10-3.19-3.192 2.257 2.257 0 003.19 3.192zM251.7 73.252a2.258 2.258 0 10-1.73-4.171 2.258 2.258 0 001.73 4.171z"
			></path>
			<path
				fill="#D9EAFF"
				d="M266.28 75.538a2.259 2.259 0 10-3.196-3.193 2.259 2.259 0 003.196 3.193zM37.352 181.929h140.521c6.029 0 10.902 4.9 10.902 10.902v83.008h-8.259v-83.008a2.673 2.673 0 00-2.671-2.67H37.352a2.673 2.673 0 00-2.67 2.67v83.008h-8.26v-83.008c.027-6.029 4.9-10.902 10.93-10.902z"
			></path>
			<path
				fill="#DEEAFB"
				d="M30.332 219.812l-18.28 55.118c-.249.743-.964 1.129-1.625.881-.689-.248-1.074-1.074-.854-1.872l15.666-56.137a.39.39 0 01.055-.165c.523-1.57 2.092-2.395 3.469-1.79 1.376.606 2.092 2.395 1.569 3.965zM80.55 275.811c-.661.248-1.377-.165-1.625-.881l-18.28-55.118c-.524-1.569.192-3.359 1.569-3.937 1.404-.606 2.945.193 3.469 1.789.027.055.027.111.054.166l15.666 56.136c.22.771-.165 1.597-.854 1.845z"
			></path>
			<path
				fill="#DEEAFB"
				d="M4.314 150.129c-2.698 0-4.735 2.451-4.24 5.094L9.739 211.8c1.46 6.828 7.489 11.728 14.454 11.728h50.63c5.122 0 8.7-5.423 6.36-9.966-.468-.908-1.073-1.542-1.734-1.652l-38.82-6.635c-4.928-.853-9.085-4.102-11.067-8.7L8.196 152.58a4.328 4.328 0 00-3.882-2.451z"
			></path>
			<path
				fill="#37384F"
				d="M87.047 212.984c-17.428 1.239-37.58 3.194-49.392 3.194-15.032 0-21.667-12.775-21.667-23.072h74.665c5.754 0 9.086 4.956 9.499 7.544l-13.105 12.334z"
			></path>
			<path
				fill="#37384F"
				d="M79.173 202.467l-41.518 13.711h44.078l-2.56-13.711zM100.124 200.622l-13.077 12.362c1.57 12.416 5.424 27.999 10.352 43.252h8.094l-5.369-55.614z"
			></path>
			<path
				fill="#C1C7D0"
				d="M99.601 256.236c.551 1.762 1.156 3.497 1.735 5.231h4.652l-.495-5.231h-5.892zM64.14 263.834l-.936 5.589h4.68l2.148-5.589H64.14z"
			></path>
			<path
				fill="#37384F"
				d="M84.817 202.246c-4.35-1.431-9.03.992-10.38 5.369-4.79 15.583-9.718 39.618-12.416 56.219h8.039l19.712-50.658c1.845-4.377-.44-9.415-4.955-10.93z"
			></path>
			<path
				fill="#29ABE2"
				d="M62.378 269.121h6.057l4.323 2.312c.77.413 1.569.771 2.395 1.046l1.266.441c1.597.578 1.019 2.973-.66 2.973H63.975a2.025 2.025 0 01-2.037-2.257l.44-4.515z"
			></path>
			<path
				fill="#37384F"
				d="M77.356 274.214c.027.798-.606 1.679-1.597 1.679H63.975a2.018 2.018 0 01-2.01-1.679h15.39zM51.999 114.311l-10.958.963-2.505 8.92-3.331 15.666 10.875 1.899c7.984-8.947 8.92-17.702 5.919-27.448z"
			></path>
			<path
				fill="#fff"
				d="M54.257 177.193c-6.305-2.781-15.886-34.057-6.415-38.104 4.625-.853 6.277 2.946 7.626 9.333l4.735 21.943-5.947 6.828z"
			></path>
			<path
				fill="#B3680A"
				d="M93.791 178.157l-2.56-.276-7.323 2.698-1.57-3.469 5.231-3.524 5.094.661 3.827 2.974c-.661.688-1.68 1.046-2.698.936z"
			></path>
			<path
				fill="#fff"
				d="M55.44 173.228l2.313-4.102 26.182 6.91v5.81H69.068c-5.368-1.019-10.764-2.699-14.812-4.626l1.184-3.992zM15.988 192.253c.275-14.812 7.764-17.73 7.764-27.229 0-8.397-.799-16.133.826-25.962 9.911-4.79 15.5-5.093 23.319-2.203a7.633 7.633 0 014.956 7.489c-.909 21.42-.468 65.222-.468 65.222 0 12.747-10.325 23.072-23.072 23.072H15.988v-40.389z"
			></path>
			<path
				fill="#fff"
				d="M33.47 177.193c-6.304-2.781-18.39-34.057-8.892-38.104 4.625-.853 8.755 2.946 10.104 9.333l4.735 21.943-5.947 6.828z"
			></path>
			<path
				fill="#C1C7D0"
				d="M73.005 178.157l-2.56-.276-7.323 2.698-1.57-3.469 5.231-3.524 5.094.661 3.827 2.974a3.314 3.314 0 01-2.699.936z"
			></path>
			<path
				fill="#fff"
				d="M34.654 173.228l2.313-4.102 26.182 6.91v6.498c-7.764 1.404-21.474-1.404-29.679-5.341l1.184-3.965z"
			></path>
			<path
				fill="#D9EAFF"
				d="M52.44 182.919l.11-.826c-6.608-.826-13.848-2.835-18.914-5.258-2.506-1.101-6.305-7.406-9.03-15.005l-.772.275c2.34 6.47 6.305 14.069 9.471 15.473 5.121 2.45 12.445 4.515 19.135 5.341z"
			></path>
			<path
				fill="#1753E2"
				d="M45.226 136.501l.799 28.688-10.215-29.266 9.416.578z"
			></path>
			<path
				fill="#C1C7D0"
				d="M39.197 121.386l7.213 8.893c-.88.11-1.459.661-1.404 1.487l.083 1.899-2.34 3.304-6.746-3.304 3.194-12.279z"
			></path>
			<path
				fill="#2864F3"
				d="M35.81 135.923l.248-2.808 6.993 2.202 2.038-1.652.137 2.781.11 3.827-2.808-2.643-5.066 3.056-1.652-4.763z"
			></path>
			<path
				fill="#37384F"
				d="M42.528 137.63l-2.23 1.376 1.844 3.662-.66 9.526 4.542 12.995-.358-12.995-2.698-9.608.964-3.607-1.404-1.349z"
			></path>
			<path
				fill="#D9EAFF"
				d="M46.383 169.567l5.038-21.805-2.23-2.148h2.367l-4.9-8.92-.744.413 4.24 7.681h-3.056l3.414 3.249-4.955 21.337.826.193z"
			></path>
			<path
				fill="#D9EAFF"
				d="M45.364 169.705l.688-.468-14.426-21.282 3.248-3.166h-4.02l2.781-7.737-.77-.275-3.194 8.837h3.166l-2.285 2.231 14.812 21.86z"
			></path>
			<path
				fill="#C1C7D0"
				d="M50.265 128.848c-.22 1.679-2.065 2.753-4.984.798l-2.698-1.762c-2.698-1.735-4.074-4.928-3.524-8.094l.193-1.102a6.154 6.154 0 01.936-2.34l.44-.633a6.02 6.02 0 016.773-2.34c2.808.881 4.543 3.661 4.13 6.58l-1.266 8.893z"
			></path>
			<path
				fill="#C1C7D0"
				d="M49.494 126.369l1.707.193c.55.055.963-.44.826-.963l-.882-3.222-1.183-.137-.468 4.129z"
			></path>
			<path
				fill="#37384F"
				d="M57.258 111.42c-1.405 1.212-2.946 1.9-4.763 1.432l-8.865-2.23c-3.938-1.046-7.406.826-8.673 5.396-1.404 4.983-2.12 12.747-7.24 21.64 8.452 1.514 16.325-4.873 16.49-13.435.056-2.754-.247-4.956-.798-6.608 3.304 1.569 15.473 4.13 13.849-6.195z"
			></path>
			<path
				fill="#29ABE2"
				d="M101.005 264.688l-.22-4.35 5.149-1.569.77 1.707c.964.496 2.038.716 3.111.578h.056a4.91 4.91 0 001.762-.578l2.037-1.074c1.679-.523 2.753 1.652 1.376 2.671l-5.616 4.102c-.661.496-1.487.743-2.313.743h-3.992c-1.211.028-2.202-1.018-2.12-2.23z"
			></path>
			<path
				fill="#37384F"
				d="M107.145 266.946h-3.992c-1.019 0-1.872-.716-2.065-1.652l6.965-.138 7.241-5.286c.523.606.606 1.624-.22 2.23l-5.617 4.102c-.688.468-1.486.744-2.312.744z"
			></path>
			<path
				fill="#8CADFF"
				d="M79.861 181.928h20.566c.606 0 1.074-.495 1.074-1.073l6.195-25.797c.137-.606-.248-1.074-.826-1.074H86.304c-.606 0-1.184.495-1.322 1.074l-6.194 25.797c0 .578.468 1.073 1.073 1.073z"
			></path>
			<path
				fill="#9DC4F2"
				d="M108.659 153.984H88.093c-.606 0-1.184.495-1.322 1.074l-6.194 25.081h-9.774v.688c0 .606.496 1.074 1.074 1.074H102.217c.605 0 1.073-.496 1.073-1.074l6.195-25.797c.138-.551-.22-1.046-.826-1.046z"
			></path>
			<path
				fill="#8CADFF"
				d="M80.55 180.139v.688c0 .606-.496 1.074-1.074 1.074H60.148a1.075 1.075 0 01-1.073-1.074v-.688h21.474z"
			></path>
			<path
				fill="#45628F"
				d="M140.32 181.928h18.089a2.091 2.091 0 100-4.184H140.32a2.091 2.091 0 100 4.184z"
			></path>
			<path
				fill="#fff"
				d="M138.228 179.836h22.273a2.091 2.091 0 00-2.092-2.092H140.32a2.091 2.091 0 00-2.092 2.092z"
			></path>
			<path
				fill="#45628F"
				d="M140.32 177.743h18.089a2.091 2.091 0 100-4.184H140.32a2.091 2.091 0 100 4.184z"
			></path>
			<path
				fill="#fff"
				d="M138.228 175.651h22.273a2.091 2.091 0 00-2.092-2.092H140.32a2.091 2.091 0 00-2.092 2.092z"
			></path>
			<path
				fill="#45628F"
				d="M140.32 173.559h18.089a2.091 2.091 0 002.092-2.093 2.091 2.091 0 00-2.092-2.092H140.32a2.091 2.091 0 00-2.092 2.092c0 1.157.936 2.093 2.092 2.093z"
			></path>
			<path
				fill="#fff"
				d="M138.228 171.466h22.273a2.091 2.091 0 00-2.092-2.092H140.32a2.091 2.091 0 00-2.092 2.092z"
			></path>
			<path
				fill="#45628F"
				d="M140.32 169.374h18.089a2.092 2.092 0 000-4.185H140.32a2.092 2.092 0 000 4.185z"
			></path>
			<path
				fill="#fff"
				d="M138.228 167.282h22.273a2.092 2.092 0 00-2.092-2.093H140.32a2.092 2.092 0 00-2.092 2.093z"
			></path>
			<path
				fill="#45628F"
				d="M140.32 165.189h18.089a2.091 2.091 0 002.092-2.092 2.091 2.091 0 00-2.092-2.093H140.32a2.091 2.091 0 00-2.092 2.093c0 1.156.936 2.092 2.092 2.092z"
			></path>
			<path
				fill="#fff"
				d="M138.228 163.097h22.273a2.091 2.091 0 00-2.092-2.093H140.32a2.091 2.091 0 00-2.092 2.093z"
			></path>
			<path
				fill="#fff"
				d="M159.455 181.929h9.471a3.514 3.514 0 003.524-3.524v-18.226a3.513 3.513 0 00-3.524-3.524h-9.471a3.513 3.513 0 00-3.524 3.524v18.226a3.513 3.513 0 003.524 3.524z"
			></path>
			<path
				fill="#45628F"
				d="M158.219 156.655h11.922v-3.469h-11.922v3.469z"
			></path>
			<path
				fill="#D6E1F0"
				d="M157.834 174.011h12.719v-10.93h-12.719v10.93z"
			></path>
			<path
				fill="#45628F"
				d="M168.678 165.905h-8.948v.689h8.948v-.689zM168.678 168.19h-8.948v.689h8.948v-.689zM168.678 170.503h-8.948v.688h8.948v-.688z"
			></path>
			<path
				fill="#fff"
				d="M131.18 179.478a1.962 1.962 0 001.954-1.955v-13.711h-3.937v13.711c.028 1.074.909 1.955 1.983 1.955z"
			></path>
			<path
				fill="#45628F"
				d="M129.28 166.291h3.827a.729.729 0 00.715-.716v-3.524a.728.728 0 00-.715-.716h-3.827a.728.728 0 00-.716.716v3.524c0 .385.303.716.716.716z"
			></path>
			<path
				fill="#fff"
				d="M125.068 179.478a1.962 1.962 0 001.954-1.955v-13.711h-3.937v13.711c0 1.074.881 1.955 1.983 1.955z"
			></path>
			<path
				fill="#45628F"
				d="M123.141 166.291h3.827a.729.729 0 00.715-.716v-3.524a.728.728 0 00-.715-.716h-3.827a.728.728 0 00-.716.716v3.524c0 .385.303.716.716.716z"
			></path>
			<path
				fill="#fff"
				d="M118.929 179.478a1.962 1.962 0 001.954-1.955v-13.711h-3.937v13.711c0 1.074.881 1.955 1.983 1.955z"
			></path>
			<path
				fill="#45628F"
				d="M117.001 166.291h3.827a.73.73 0 00.716-.716v-3.524a.73.73 0 00-.716-.716h-3.827a.73.73 0 00-.716.716v3.524a.73.73 0 00.716.716z"
			></path>
			<path
				fill="#fff"
				d="M112.788 179.478a1.962 1.962 0 001.955-1.955v-13.711h-3.937v13.711c0 1.074.881 1.955 1.982 1.955z"
			></path>
			<path
				fill="#45628F"
				d="M110.861 166.291h3.827a.73.73 0 00.716-.716v-3.524a.728.728 0 00-.716-.716h-3.827a.728.728 0 00-.715.716v3.524c0 .385.33.716.715.716z"
			></path>
			<path
				fill="#fff"
				d="M106.649 179.478a1.962 1.962 0 001.955-1.955v-13.711h-3.937v13.711c.028 1.074.881 1.955 1.982 1.955z"
			></path>
			<path
				fill="#45628F"
				d="M104.722 166.291h3.827a.73.73 0 00.716-.716v-3.524a.73.73 0 00-.716-.716h-3.827a.73.73 0 00-.716.716v3.524a.73.73 0 00.716.716zM104.088 172.32h29.652a.67.67 0 00.661-.661.67.67 0 00-.661-.66h-29.652a.67.67 0 00-.66.66c0 .358.275.661.66.661z"
			></path>
			<path
				fill="#fff"
				d="M105.163 179.918h27.531c1.101 0 2.01.909 2.01 2.01h-31.551c0-1.101.908-2.01 2.01-2.01z"
			></path>
			<path
				fill="#D9EAFF"
				d="M139.962 55.889c7.924-7.924 7.924-20.772 0-28.696-7.924-7.924-20.771-7.924-28.695 0-7.924 7.924-7.924 20.772 0 28.696 7.924 7.924 20.771 7.924 28.695 0z"
			></path>
			<path
				fill="#fff"
				d="M135.172 35.075l2.313-3.056-2.313-2.312-3.056 2.312c-1.266-.853-2.726-1.486-4.295-1.762l-.551-3.799h-3.276l-.55 3.8c-1.57.302-3.029.908-4.295 1.761l-3.056-2.312-2.313 2.312 2.313 3.056c-.854 1.267-1.487 2.726-1.762 4.295l-3.8.55v3.277l3.8.55c.302 1.57.908 3.03 1.762 4.296l-2.313 3.056 2.313 2.312 3.056-2.312c1.266.853 2.725 1.486 4.295 1.762l.55 3.8h3.276l.551-3.8a11.621 11.621 0 004.295-1.762l3.056 2.312 2.313-2.312-2.313-3.056c.853-1.267 1.487-2.726 1.762-4.295l3.799-.55V39.92l-3.799-.55a11.631 11.631 0 00-1.762-4.296z"
			></path>
			<path
				fill="#D6E1F0"
				d="M128.763 49.45a8.507 8.507 0 10-6.288-15.81 8.507 8.507 0 006.288 15.81z"
			></path>
			<path
				fill="#45628F"
				d="M131.211 40.063a3.36 3.36 0 10-6.494-1.726 3.36 3.36 0 006.494 1.726z"
			></path>
			<path
				fill="#fff"
				d="M127.959 43.417c-2.341 0-4.24-1.9-4.24-4.24 0-2.34 1.899-4.24 4.24-4.24a4.241 4.241 0 010 8.48zm0-6.69a2.456 2.456 0 00-2.451 2.45 2.456 2.456 0 002.451 2.45 2.456 2.456 0 002.45-2.45c.027-1.349-1.074-2.45-2.45-2.45z"
			></path>
			<path
				fill="#45628F"
				d="M126.59 44.442a3.36 3.36 0 10-6.634-1.068 3.36 3.36 0 006.634 1.068z"
			></path>
			<path
				fill="#fff"
				d="M123.251 48.153c-2.341 0-4.24-1.9-4.24-4.24 0-2.34 1.899-4.24 4.24-4.24a4.241 4.241 0 010 8.48zm0-6.718a2.456 2.456 0 00-2.451 2.45 2.456 2.456 0 002.451 2.45 2.456 2.456 0 002.45-2.45c.027-1.349-1.101-2.45-2.45-2.45z"
			></path>
			<path
				fill="#fff"
				d="M122.7 41.435a2.82 2.82 0 01-2.808-2.808 2.82 2.82 0 012.808-2.808 2.82 2.82 0 012.809 2.808 2.82 2.82 0 01-2.809 2.808zm0-3.827c-.578 0-1.018.468-1.018 1.02 0 .577.468 1.018 1.018 1.018.578 0 1.019-.468 1.019-1.019 0-.55-.441-1.019-1.019-1.019zM128.619 47.382a2.82 2.82 0 01-2.808-2.808 2.82 2.82 0 012.808-2.808 2.82 2.82 0 012.808 2.808 2.802 2.802 0 01-2.808 2.808zm0-3.854c-.578 0-1.018.468-1.018 1.018 0 .578.468 1.019 1.018 1.019.551 0 1.019-.468 1.019-1.019.027-.55-.441-1.018-1.019-1.018z"
			></path>
			<path
				fill="#D9EAFF"
				d="M79.255 116.073c12.94 0 23.43-10.49 23.43-23.43 0-12.939-10.49-23.429-23.43-23.429s-23.429 10.49-23.429 23.43c0 12.939 10.49 23.429 23.43 23.429z"
			></path>
			<path
				fill="#fff"
				d="M90.35 104.895l-6.965-19.106V79.07h-8.26v6.718l-6.965 19.106c-.826 2.258.854 4.653 3.249 4.653h15.665c2.423.028 4.103-2.367 3.277-4.653z"
			></path>
			<path
				fill="#D6E1F0"
				d="M81.045 86.228V78.08h-3.58v8.122l-3 8.232h9.58l-3-8.205z"
			></path>
			<path
				fill="#45628F"
				d="M84.046 94.434h-9.554l-4.102 11.26c-.165.468 0 .853.138 1.019.137.192.413.468.908.468h15.666a1.1 1.1 0 00.908-.468c.138-.193.303-.551.138-1.019l-4.102-11.26z"
			></path>
			<path
				fill="#fff"
				d="M83.826 79.098h-9.113c-.936 0-1.68-.743-1.68-1.68 0-.935.744-1.679 1.68-1.679h9.113c.936 0 1.679.744 1.679 1.68 0 .908-.77 1.68-1.68 1.68zM75.676 104.648a1.735 1.735 0 100-3.47 1.735 1.735 0 000 3.47zM82.835 102.555a1.735 1.735 0 10-.001-3.47 1.735 1.735 0 000 3.47zM78.815 96.168a1.734 1.734 0 100-3.47 1.734 1.734 0 000 3.47z"
			></path>
			<path
				fill="#F492B8"
				d="M316.88 216.783l-.964-50.63h23.65c-.441 16.216-4.02 34.607-8.095 50.63H316.88z"
			></path>
			<path
				fill="#F492B8"
				d="M334.39 216.784l-2.973-50.659h23.65c1.404 16.217-3.827 34.608-6.085 50.631l-14.592.028z"
			></path>
			<path
				fill="#C1C7D0"
				d="M348.954 216.783c1.432 18.336-1.211 34.277-5.258 55.752h-5.121l-4.213-55.752h14.592z"
			></path>
			<path
				fill="#1753E2"
				d="M320.101 153.984a31.21 31.21 0 001.679-11.012c-.192-7.076-.495-19.19-.495-26.761a7.581 7.581 0 014.983-7.131c7.241-2.643 13.325-2.946 21.089-.165 3.221 1.156 5.314 4.267 5.094 7.681-.469 7.406-1.983 16.877-3.277 23.925a31.207 31.207 0 002.836 19.63 30.818 30.818 0 013.359 13.794c.027 14.151-2.643 29.321-4.543 42.811h-35.791l.661-37.773a82.312 82.312 0 014.405-24.999z"
			></path>
			<path
				fill="#fff"
				d="M356.938 173.559a41.728 41.728 0 00-3.028-13.022 41.813 41.813 0 01-2.918-20.071c.743-7.158 1.597-16.766 1.707-24.227.055-3.194-1.9-6.085-4.901-7.186-3.111-1.129-5.947-1.79-8.672-2.01l-6.966 23.952-1.762-23.209c-1.487.358-3.469.826-5.479 1.404a7.558 7.558 0 00-5.478 7.351c.027 6.03.413 14.399.633 21.558a50.88 50.88 0 01-3.221 19.519 50.839 50.839 0 00-3.194 15.528l-2.037 43.61h9.966l9.966-31.193 9.912 31.221 15.748-.055c.33-16.134.523-31.689-.276-43.17z"
			></path>
			<path
				fill="#fff"
				d="M349.973 110.292l-2.753 34.579 7.241 2.726a13.23 13.23 0 003.331-7.461c.33-3.111.771-7.599 1.129-12.334.44-5.727-.744-12.665-8.948-17.51z"
			></path>
			<path
				fill="#C1C7D0"
				d="M331.086 100.022l.716 10.985 6.47-3.937-.881-10.131-6.305 3.083z"
			></path>
			<path
				fill="#C1C7D0"
				d="M326.516 99.113c-.028 1.735 1.679 3.277 4.928 2.038l5.974-4.24 1.735-6.553-1.211-2.615a5.932 5.932 0 00-5.782-3.441c-3.139.192-5.589 2.78-5.589 5.919l-.055 8.892z"
			></path>
			<path
				fill="#fff"
				d="M326.901 97.957c.331.799 1.212 1.432 2.01 1.432.826 0 1.294-.633 1.129-1.431h-3.139z"
			></path>
			<path
				fill="#C1C7D0"
				d="M328.14 96.663h-1.762a.742.742 0 01-.688-1.019l1.239-3.138h1.184v4.157h.027z"
			></path>
			<path
				fill="#13375B"
				d="M324.231 82.072c1.872 1.79 7.02-.165 11.701.468 14.756 1.982 13.765 23.402 13.765 23.402-3.882 3.111-8.59 4.267-13.215 3.496-1.9-3.882-3.111-12.61-3.111-20.043-4.295 1.404-10.902-.303-9.14-7.323z"
			></path>
			<path
				fill="#C1C7D0"
				d="M330.976 216.783c-1.184 18.336-4.46 34.277-11.563 55.752h-5.121l2.092-55.752h14.592z"
			></path>
			<path
				fill="#fff"
				d="M297.085 124.828l-2.643 6.828c5.837 1.239 22.3-3.827 29.486-7.902l-.716-13.82c-10.985 5.176-26.127 14.894-26.127 14.894z"
			></path>
			<path
				fill="#fff"
				d="M294.442 131.656l4.735-1.735 1.377-2.285c-3.634-9.553-6.911-21.557-8.948-26.568l-2.973.634c-1.542 11.756.137 21.86 5.809 29.954z"
			></path>
			<path
				fill="#29ABE2"
				d="M319.936 272.342h-6.057l-4.322 2.313c-.771.413-1.57.771-2.396 1.046l-1.266.441c-1.597.578-1.019 2.973.661 2.973h11.783a2.024 2.024 0 002.037-2.258l-.44-4.515z"
			></path>
			<path
				fill="#37384F"
				d="M304.931 277.408c-.027.798.606 1.679 1.597 1.679h11.783a2.018 2.018 0 002.01-1.679h-15.39z"
			></path>
			<path
				fill="#29ABE2"
				d="M344.412 272.342h-6.057l-4.323 2.313c-.771.413-1.569.771-2.395 1.046l-1.267.441c-1.596.578-1.018 2.973.661 2.973h11.784a2.024 2.024 0 002.037-2.258l-.44-4.515z"
			></path>
			<path
				fill="#37384F"
				d="M329.434 277.408c-.027.798.606 1.679 1.597 1.679h11.784a2.02 2.02 0 002.01-1.679h-15.391z"
			></path>
			<path
				fill="#fff"
				d="M303.72 108.006l-5.313-14.537v-5.093h-6.278v5.093l-5.313 14.537c-.633 1.734.66 3.552 2.478 3.552h11.921c1.844 0 3.138-1.818 2.505-3.552z"
			></path>
			<path
				fill="#D6E1F0"
				d="M296.617 93.772v-6.166h-2.698v6.166l-2.285 6.278h7.268l-2.285-6.278z"
			></path>
			<path
				fill="#45628F"
				d="M298.902 100.049h-7.268l-3.139 8.563c-.137.358 0 .66.11.77a.79.79 0 00.689.358h11.921c.385 0 .606-.22.688-.358.083-.137.248-.412.11-.77l-3.111-8.563z"
			></path>
			<path
				fill="#fff"
				d="M298.737 88.376h-6.938a1.292 1.292 0 01-1.294-1.293c0-.716.578-1.294 1.294-1.294h6.938c.716 0 1.294.578 1.294 1.294 0 .715-.578 1.293-1.294 1.293zM293.864 106.492c0 .716-.578 1.321-1.322 1.321a1.304 1.304 0 01-1.321-1.321 1.322 1.322 0 112.643 0zM299.315 104.895c0 .716-.578 1.322-1.322 1.322a1.304 1.304 0 01-1.321-1.322 1.321 1.321 0 012.643 0zM295.626 103.216a.853.853 0 100-1.707.853.853 0 000 1.707z"
			></path>
			<path
				fill="#C1C7D0"
				d="M288.66 101.729l.744-3.607a3.84 3.84 0 013.166-3l3.661-.551-.247 6.222-5.176 1.817-2.148-.881z"
			></path>
			<path
				fill="#fff"
				d="M347.137 142.366l7.323 5.231c-2.34 2.23-5.368 3.276-9.718 1.872-6.03-1.322-23.182-12.499-26.128-18.694l2.808-.881 25.715 12.472z"
			></path>
			<path
				fill="#D9EAFF"
				d="M344.632 149.882l.165-.799c-4.846-1.073-17.538-8.892-23.374-15.142l-.606.551c5.974 6.359 18.887 14.316 23.815 15.39zM347.495 143.136l1.79-22.631-.826-.082-1.68 21.254-25.439-12.334-.358.743 25.687 12.472.826.578z"
			></path>
			<path
				fill="#C1C7D0"
				d="M318.615 130.775l-.744-1.074a3.217 3.217 0 01-.908-4.129l.936-1.68-1.927-4.129.578-1.047 5.148 6.883-.22 4.295-2.863.881z"
			></path>
		</svg>
	)
}
