import React from "react"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"

export const NeuronIllustration: Illustration = ({
	className = "",
	...props
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 129 79"
			className={mergeClasses({ classes: ["w-20", className] })}
			{...props}
		>
			<g fill="#9EC8FF" opacity="0.5">
				<path d="M41.282 57.913H24.95a.796.796 0 01-.689-.397l-8.166-14.114a.792.792 0 010-.794l8.166-14.113a.794.794 0 01.69-.397h16.33a.796.796 0 01.69.397l8.166 14.113a.793.793 0 010 .794L41.97 57.516a.794.794 0 01-.69.397zm-15.873-1.588h15.414l7.706-13.32-7.706-13.32H25.409l-7.706 13.32 7.706 13.32z"></path>
				<path d="M45.639 43.402a.397.397 0 01-.345-.198l-6.26-10.822a.395.395 0 01.292-.591.399.399 0 01.396.194l6.261 10.822a.396.396 0 01-.344.595zM20.593 43.402a.398.398 0 01-.344-.595l6.261-10.821a.398.398 0 01.689.396l-6.261 10.822a.397.397 0 01-.345.198zM39.376 54.223H26.854a.398.398 0 01-.281-.677.398.398 0 01.281-.116h12.522a.398.398 0 01.282.677.398.398 0 01-.282.116zM74.097 57.913a.797.797 0 01-.69-.397l-8.166-14.114a.791.791 0 010-.794l8.166-14.113a.794.794 0 01.69-.397h16.331a.796.796 0 01.795.794.793.793 0 01-.795.793H74.555l-7.706 13.32 7.936 13.717a.792.792 0 01-.688 1.191zM16.547 72.027a.797.797 0 01-.795-.794c0-.14.037-.276.107-.397l8.166-14.114a.795.795 0 011.378.794L17.236 71.63a.794.794 0 01-.69.397z"></path>
				<path d="M90.426 29.685a.796.796 0 01-.688-1.19l8.166-14.115a.795.795 0 011.378.794l-8.166 14.114a.794.794 0 01-.69.397zM65.93 43.799H49.598a.796.796 0 01-.689-.397l-8.166-14.114a.793.793 0 010-.794l8.166-14.113a.792.792 0 01.793-.39.797.797 0 01.664.581.79.79 0 01-.08.602L42.35 28.891l7.707 13.32H65.93a.796.796 0 01.795.794.793.793 0 01-.795.794zM126.567 10.563h-22.984a2.119 2.119 0 01-2.117-2.112 2.118 2.118 0 012.117-2.113h22.984a2.118 2.118 0 012.117 2.113 2.119 2.119 0 01-2.117 2.113zM126.566 4.225h-22.984a2.123 2.123 0 01-1.958-1.304 2.118 2.118 0 010-1.617A2.118 2.118 0 01103.582 0h22.984a2.119 2.119 0 011.958 1.304 2.118 2.118 0 010 1.617 2.118 2.118 0 01-1.958 1.304zM108.079 33.498h-9.9a2.301 2.301 0 110-4.607h9.9c.612 0 1.2.243 1.633.675a2.3 2.3 0 010 3.258 2.313 2.313 0 01-1.633.674zM53.833 6.338h8.619c.722 0 1.414.286 1.925.796.51.51.797 1.2.797 1.92v.302a2.71 2.71 0 01-1.68 2.51c-.33.136-.685.206-1.042.206h-8.62a2.724 2.724 0 01-1.924-.795c-.51-.51-.797-1.2-.797-1.92v-.303c0-.72.287-1.41.797-1.92a2.725 2.725 0 011.925-.796zM79.841 59.603h16.936c.722 0 1.415.286 1.925.795.51.51.797 1.2.797 1.921v1.207c0 .72-.287 1.411-.797 1.92-.51.51-1.203.796-1.925.796H79.841a2.725 2.725 0 01-1.924-.795c-.51-.51-.798-1.2-.798-1.92v-1.208a2.71 2.71 0 011.68-2.51c.33-.136.685-.206 1.042-.206zM2.722 73.032H12.4c.721 0 1.414.287 1.924.796.51.51.797 1.2.797 1.92v.151a2.711 2.711 0 01-1.68 2.51c-.33.136-.684.206-1.041.206H2.722a2.725 2.725 0 01-1.925-.795C.287 77.31 0 76.62 0 75.9v-.152c0-.72.287-1.41.797-1.92a2.725 2.725 0 011.925-.796z"></path>
			</g>
		</svg>
	)
}
