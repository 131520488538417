import React from "react"
import { graphql } from "gatsby"
import { Container } from "components/library"
import { MiniNavbar } from "components/common/mini-navbar"
import { CtaBlock } from "components/common/cta-block"
import { FeaturesOfTheAiEngine } from "components/pages/products/ai/features-of-the-ai/features-of-the-ai"
import { Hero } from "components/pages/products/ai/hero"
import { AiEngineAtPolymerize } from "components/pages/products/ai/ai-engine-at-polymerize"
import { AiEngineHardWork } from "components/pages/products/ai/ai-engine-hard-work"

const ProductPage = () => {
	return (
		<main>
			<Container className="flex flex-col gap-20 sm:gap-32 py-16 sm:py-12">
				<div className="flex flex-col gap-8 sm:gap-12">
					<MiniNavbar
						className="hidden sm:flex"
						title="common.words.ai-engine"
						links={[
							{
								label: "common.words.ai-at-polymerize",
								path: "#ai-at-polymerize",
							},
							{ label: "common.words.features-of-ai", path: "#features-of-ai" },
						]}
					/>
					<Hero />
				</div>
				<AiEngineAtPolymerize />
				<AiEngineHardWork />
				<FeaturesOfTheAiEngine />
			</Container>

			<CtaBlock />
		</main>
	)
}

export default ProductPage

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
