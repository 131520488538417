import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import FormulationTable from "images/formulation-table.png"
import { AiEngineIllustration } from "components/library/illustrations/ai-engine-illustration";
import { IngredientsTransformationIllustration } from "components/library/illustrations/ingredients-transformation";
import { NeuronIllustration } from "components/library/illustrations/neuron";

export const AiEngineAtPolymerize = () => {
	const { t } = useTranslation()

	return (
		<section
			id="ai-at-polymerize"
			className="w-full scroll-top-margin rounded-md bg-gradient-to-br from-[#CDDBF9] via-[#FCEADC] to-[#9BAAE3] p-8 sm:p-16 flex flex-col gap-16 sm:gap-8"
		>
			<div className="flex flex-col items-center text-center gap-8">
				<AiEngineIllustration className="w-20" />

				<h2 className="text-theme-blue text-3xl sm:text-4xl md:text-5xl">
					{t("products-ai-page.ai-at-polymerize.heading")}
				</h2>

				<p className="paragraph">
					{t("products-ai-page.ai-at-polymerize.points.1")}
				</p>

				<div className="flex flex-col md:flex-row gap-28 sm:gap-40 md:gap-0 items-center justify-between w-full">
					<IngredientsTransformationIllustration className="md:w-[35%] w-full" />

					<div className="flex md:flex-col flex-row gap-5">
						<div className="flex transform rotate-90 md:rotate-0 translate-x-12 sm:translate-x-0 flex-col gap-1">
							<p className="uppercase text-theme-blue">Properties</p>
							<div className="w-44 bg-black text-black h-[1px] relative">
								<span className="arrow right-arrow absolute right-0 -top-[3px]"></span>
							</div>
						</div>
						<div className="flex transform rotate-90 md:rotate-0 -translate-x-12 sm:translate-x-0 flex-col gap-1">
							<div className="w-44 bg-black h-[1px] text-black relative">
								<span className="arrow left-arrow absolute left-0 -top-[3px]"></span>
							</div>
							<p className="uppercase text-theme-blue">Formulation</p>
						</div>
					</div>

					<div className="relative md:w-[35%] z-[1]">
						<NeuronIllustration className="absolute w-32 z-[-1] isolate md:-left-20 md:bottom-6 -top-10 md:top-auto transform rotate-90 md:rotate-0"></NeuronIllustration>
						<img
							src={FormulationTable}
							alt="formulation table"
							className="w-full"
						/>
					</div>
				</div>

				<p className="paragraph">
					{t("products-ai-page.ai-at-polymerize.points.2")}
				</p>
			</div>
		</section>
	)
}
