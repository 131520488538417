import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import { AiMlMachineIllustration } from "components/library/illustrations/lab/ai-ml-machine";

export const AiEngineHardWork = () => {
	const { t } = useTranslation()

	return (
		<section className="flex flex-col gap-16">
			<div className="text-center flex flex-col gap-4 items-center">
				<h2 className="text-theme-blue text-3xl sm:text-4xl md:text-5xl">
					{t("products-ai-page.ai-engine-hard-work.heading")}
				</h2>
				<p className="sm:w-[65%] text-lg sm:text-xl">
					{t("products-ai-page.ai-engine-hard-work.desc")}
				</p>
			</div>

			<div>
				<AiMlMachineIllustration className="w-full" />
			</div>

			<div className="flex flex-col gap-4 items-center sm:text-center">
				<StaticImage
					alt="ai engine"
					src="../../../../images/icon_use_cases.png"
					className="w-20 sm:w-32"
				/>
				<p className="sm:text-lg">
					{t("products-ai-page.ai-engine-hard-work.points.1")}
				</p>
				<p className="sm:text-lg sm:w-[85%]">
					{t("products-ai-page.ai-engine-hard-work.points.2")}
				</p>
			</div>
		</section>
	)
}
