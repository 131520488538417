import React, { ComponentPropsWithoutRef } from "react"
import { mergeClasses } from "utils"

type P = ComponentPropsWithoutRef<"svg">

export const ExperimentIllustration = ({ className = "", ...props }: P) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			className={mergeClasses({ classes: ["w-80", className] })}
			viewBox="0 0 380 352"
			{...props}
		>
			<rect
				width="330.878"
				height="173.753"
				y="95.578"
				fill="#C1C7D0"
				rx="4"
			></rect>
			<rect
				width="314.251"
				height="154.631"
				x="8.314"
				y="106.385"
				fill="#FAFBFC"
				rx="4"
			></rect>
			<path
				fill="#DFE1E6"
				d="M138.836 269.33H192.04200000000003V290.114H138.836z"
			></path>
			<path fill="#C1C7D0" d="M105.582 290.114H227.791V298.428H105.582z"></path>
			<path fill="#DFE1E6" d="M91.489 71.496H123.912V119.714H91.489z"></path>
			<path fill="#505F79" d="M34.125 72.328H39.113V290.974H34.125z"></path>
			<path
				fill="#505F79"
				d="M22.486 80.641H27.474V185.39100000000002H22.486z"
				transform="rotate(-90 22.486 80.641)"
			></path>
			<path
				fill="#C1C7D0"
				d="M24.98 298.973a8 8 0 018-8h7.278a8 8 0 018 8v.314H24.98v-.314z"
			></path>
			<path fill="#C1C7D0" d="M30.8 72.328H42.439V83.967H30.8z"></path>
			<path
				fill="#DFE1E6"
				d="M87.332 54.038H128.06799999999998V67.34H87.332z"
			></path>
			<path
				fill="#C1C7D0"
				d="M110.61 67.34H128.06799999999998V71.497H110.61z"
			></path>
			<path
				fill="#DFE1E6"
				d="M87.332 67.34H110.60999999999999V71.497H87.332z"
			></path>
			<path
				fill="#DFE1E6"
				d="M89.294 115.83a7.999 7.999 0 017.516-5.26h21.78a7.998 7.998 0 017.516 5.26l32.963 90.413c1.903 5.218-1.961 10.74-7.516 10.74H63.847c-5.555 0-9.419-5.522-7.516-10.74l32.963-90.413z"
			></path>
			<path
				fill="#3C67F0"
				d="M92.481 122.337a4 4 0 013.755-2.622h22.928a4 4 0 013.755 2.622l30.611 83.449c.958 2.61-.975 5.377-3.755 5.377h-84.15c-2.78 0-4.713-2.767-3.755-5.377l30.611-83.449z"
			></path>
			<mask
				id="mask0_7752_42677"
				style={{ maskType: "alpha" }}
				width="93"
				height="93"
				x="61"
				y="119"
				maskUnits="userSpaceOnUse"
			>
				<path
					fill="#3C67F0"
					d="M92.565 122.617a4 4 0 013.757-2.625h22.756a4 4 0 013.757 2.625l30.425 83.172c.955 2.61-.978 5.374-3.757 5.374H65.897c-2.78 0-4.711-2.764-3.757-5.374l30.425-83.172z"
				></path>
			</mask>
			<g mask="url(#mask0_7752_42677)">
				<g opacity="0.7">
					<ellipse
						cx="93.663"
						cy="223.526"
						fill="#A6BFF7"
						rx="8.78"
						ry="8.942"
						transform="rotate(134.036 93.663 223.526)"
					></ellipse>
					<ellipse
						cx="116.492"
						cy="199.915"
						fill="#A6BFF7"
						rx="8.78"
						ry="8.942"
						transform="rotate(134.036 116.492 199.915)"
					></ellipse>
					<ellipse
						cx="139.32"
						cy="176.305"
						fill="#A6BFF7"
						rx="8.78"
						ry="8.942"
						transform="rotate(134.036 139.32 176.305)"
					></ellipse>
					<circle
						cx="110.752"
						cy="216.914"
						r="5.313"
						fill="#FAD8BD"
						transform="rotate(-45.964 110.752 216.914)"
					></circle>
					<circle
						cx="133.582"
						cy="193.304"
						r="5.313"
						fill="#FAD8BD"
						transform="rotate(-45.964 133.582 193.304)"
					></circle>
					<path
						fill="url(#paint0_linear_7752_42677)"
						d="M125.99 209.037H131.19299999999998V209.362H125.99z"
						transform="rotate(-135.964 125.99 209.037)"
					></path>
					<circle
						cx="150.573"
						cy="187.04"
						r="3.032"
						fill="#7BC598"
						transform="rotate(-45.964 150.573 187.04)"
					></circle>
					<path
						fill="url(#paint1_linear_7752_42677)"
						d="M107.289 190.789H112.492V191.11399999999998H107.289z"
						transform="rotate(44.036 107.289 190.789)"
					></path>
					<path
						fill="url(#paint2_linear_7752_42677)"
						d="M148.82 185.427H154.023V185.75199999999998H148.82z"
						transform="rotate(-135.964 148.82 185.427)"
					></path>
					<circle
						cx="82.676"
						cy="212.82"
						r="3.032"
						fill="#AADABD"
						transform="rotate(134.036 82.676 212.82)"
					></circle>
					<circle
						cx="128.365"
						cy="165.566"
						r="3.032"
						fill="#7BC598"
						transform="rotate(134.036 128.365 165.566)"
					></circle>
					<path
						fill="url(#paint3_linear_7752_42677)"
						d="M130.119 167.178H135.322V167.503H130.119z"
						transform="rotate(44.036 130.119 167.178)"
					></path>
					<path
						fill="url(#paint4_linear_7752_42677)"
						d="M128.93 195.368H133.67700000000002V195.73499999999999H128.93z"
						transform="rotate(164.036 128.93 195.368)"
					></path>
					<path
						fill="url(#paint5_linear_7752_42677)"
						d="M0 0H4.747V0.367H0z"
						transform="scale(1 -1) rotate(75.964 192.518 -34.202)"
					></path>
					<path
						fill="url(#paint6_linear_7752_42677)"
						d="M0 0H4.747V0.367H0z"
						transform="scale(1 -1) rotate(75.964 188.814 -7.777)"
					></path>
					<circle
						cx="105.537"
						cy="189.177"
						r="3.032"
						fill="#AADABD"
						transform="rotate(134.036 105.537 189.177)"
					></circle>
					<circle
						cx="127.744"
						cy="210.65"
						r="3.032"
						fill="#AADABD"
						transform="rotate(-45.964 127.744 210.65)"
					></circle>
				</g>
				<g opacity="0.7">
					<ellipse
						cx="67.638"
						cy="190.673"
						fill="#A6BFF7"
						rx="8.78"
						ry="8.942"
						transform="rotate(134.036 67.638 190.673)"
					></ellipse>
					<ellipse
						cx="90.467"
						cy="167.063"
						fill="#A6BFF7"
						rx="8.78"
						ry="8.942"
						transform="rotate(134.036 90.467 167.063)"
					></ellipse>
					<circle
						cx="61.898"
						cy="207.671"
						r="5.313"
						fill="#FAD8BD"
						transform="rotate(-45.964 61.898 207.671)"
					></circle>
					<circle
						cx="84.728"
						cy="184.062"
						r="5.313"
						fill="#FAD8BD"
						transform="rotate(-45.964 84.728 184.062)"
					></circle>
					<path
						fill="url(#paint7_linear_7752_42677)"
						d="M77.137 199.794H82.34V200.119H77.137z"
						transform="rotate(-135.964 77.137 199.794)"
					></path>
					<path
						fill="url(#paint8_linear_7752_42677)"
						d="M58.436 181.546H63.639V181.87099999999998H58.436z"
						transform="rotate(44.036 58.436 181.546)"
					></path>
					<path
						fill="url(#paint9_linear_7752_42677)"
						d="M99.966 176.184H105.169V176.509H99.966z"
						transform="rotate(-135.964 99.966 176.184)"
					></path>
					<path
						fill="url(#paint10_linear_7752_42677)"
						d="M81.265 157.936H86.468V158.261H81.265z"
						transform="rotate(44.036 81.265 157.936)"
					></path>
					<path
						fill="url(#paint11_linear_7752_42677)"
						d="M80.077 186.126H84.824V186.493H80.077z"
						transform="rotate(164.036 80.077 186.126)"
					></path>
					<path
						fill="url(#paint12_linear_7752_42677)"
						d="M0 0H4.747V0.367H0z"
						transform="scale(1 -1) rotate(75.964 162.172 -60.866)"
					></path>
					<path
						fill="url(#paint13_linear_7752_42677)"
						d="M0 0H4.747V0.367H0z"
						transform="scale(1 -1) rotate(75.964 158.467 -34.441)"
					></path>
					<circle
						cx="101.719"
						cy="177.797"
						r="3.032"
						fill="#AADABD"
						transform="rotate(-45.964 101.719 177.797)"
					></circle>
					<circle
						cx="78.89"
						cy="201.408"
						r="3.032"
						fill="#AADABD"
						transform="rotate(-45.964 78.89 201.408)"
					></circle>
					<circle
						cx="79.511"
						cy="156.324"
						r="3.032"
						fill="#AADABD"
						transform="rotate(134.036 79.511 156.324)"
					></circle>
				</g>
				<g opacity="0.7">
					<ellipse
						cx="103.317"
						cy="151.1"
						fill="#A6BFF7"
						rx="8.78"
						ry="8.942"
						transform="rotate(134.036 103.317 151.1)"
					></ellipse>
					<ellipse
						cx="126.147"
						cy="127.49"
						fill="#A6BFF7"
						rx="8.78"
						ry="8.942"
						transform="rotate(134.036 126.147 127.49)"
					></ellipse>
					<circle
						cx="120.406"
						cy="144.488"
						r="5.313"
						fill="#FAD8BD"
						transform="rotate(-45.964 120.406 144.488)"
					></circle>
					<circle
						cx="143.236"
						cy="120.879"
						r="5.313"
						fill="#FAD8BD"
						transform="rotate(-45.964 143.236 120.879)"
					></circle>
					<circle
						cx="137.398"
						cy="138.224"
						r="3.032"
						fill="#7BC598"
						transform="rotate(-45.964 137.398 138.224)"
					></circle>
					<circle
						cx="114.538"
						cy="161.867"
						r="3.032"
						fill="#AADABD"
						transform="rotate(-45.964 114.538 161.867)"
					></circle>
					<path
						fill="url(#paint14_linear_7752_42677)"
						d="M135.646 136.612H140.849V136.93699999999998H135.646z"
						transform="rotate(-135.964 135.646 136.612)"
					></path>
					<circle
						cx="115.192"
						cy="116.751"
						r="3.032"
						fill="#7BC598"
						transform="rotate(134.036 115.192 116.751)"
					></circle>
					<path
						fill="url(#paint15_linear_7752_42677)"
						d="M112.785 160.255H117.988V160.57999999999998H112.785z"
						transform="rotate(-135.964 112.785 160.255)"
					></path>
					<path
						fill="url(#paint16_linear_7752_42677)"
						d="M116.944 118.363H122.147V118.688H116.944z"
						transform="rotate(44.036 116.944 118.363)"
					></path>
					<circle
						cx="92.331"
						cy="140.394"
						r="3.032"
						fill="#AADABD"
						transform="rotate(134.036 92.33 140.394)"
					></circle>
					<path
						fill="url(#paint17_linear_7752_42677)"
						d="M94.084 142.006H99.287V142.331H94.084z"
						transform="rotate(44.036 94.084 142.006)"
					></path>
					<path
						fill="url(#paint18_linear_7752_42677)"
						d="M115.755 146.552H120.502V146.91899999999998H115.755z"
						transform="rotate(164.036 115.755 146.552)"
					></path>
					<path
						fill="url(#paint19_linear_7752_42677)"
						d="M138.585 122.943H143.332V123.31H138.585z"
						transform="rotate(164.036 138.585 122.943)"
					></path>
					<path
						fill="url(#paint20_linear_7752_42677)"
						d="M0 0H4.747V0.367H0z"
						transform="scale(1 -1) rotate(75.964 150.965 8.194)"
					></path>
				</g>
			</g>
			<rect
				width="33.254"
				height="33.254"
				x="115.598"
				y="136.342"
				fill="#AADABD"
				rx="4"
			></rect>
			<path
				stroke="#fff"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M140.539 146.734l-11.431 11.431-5.196-5.196"
			></path>
			<circle
				cx="322.604"
				cy="67.34"
				r="53.538"
				stroke="#253858"
				opacity="0.1"
			></circle>
			<circle
				cx="291.366"
				cy="283.203"
				r="46.639"
				stroke="#253858"
				opacity="0.1"
				transform="rotate(75 291.366 283.203)"
			></circle>
			<ellipse
				cx="296.223"
				cy="76.674"
				fill="#A6BFF7"
				rx="8.036"
				ry="8.185"
				transform="rotate(159.031 296.223 76.674)"
			></ellipse>
			<ellipse
				cx="324.294"
				cy="65.916"
				fill="#A6BFF7"
				rx="8.036"
				ry="8.185"
				transform="rotate(159.031 324.294 65.916)"
			></ellipse>
			<ellipse
				cx="352.364"
				cy="55.158"
				fill="#A6BFF7"
				rx="8.036"
				ry="8.185"
				transform="rotate(159.031 352.364 55.158)"
			></ellipse>
			<circle
				cx="312.958"
				cy="77.798"
				r="4.864"
				fill="#FAD8BD"
				transform="rotate(-20.969 312.958 77.798)"
			></circle>
			<circle
				cx="341.029"
				cy="67.041"
				r="4.864"
				fill="#FAD8BD"
				transform="rotate(-20.969 341.029 67.04)"
			></circle>
			<circle
				cx="329.477"
				cy="79.174"
				r="2.775"
				fill="#7BC598"
				transform="rotate(-20.969 329.477 79.174)"
			></circle>
			<circle
				cx="301.368"
				cy="89.946"
				r="2.775"
				fill="#7BC598"
				transform="rotate(-20.969 301.368 89.946)"
			></circle>
			<path
				fill="url(#paint21_linear_7752_42677)"
				d="M328.646 77.157H333.408V77.455H328.646z"
				transform="rotate(-110.969 328.646 77.157)"
			></path>
			<circle
				cx="357.549"
				cy="68.416"
				r="2.775"
				fill="#7BC598"
				transform="rotate(-20.969 357.549 68.416)"
			></circle>
			<circle
				cx="319.359"
				cy="52.77"
				r="2.775"
				fill="#7BC598"
				transform="rotate(159.031 319.359 52.77)"
			></circle>
			<path
				fill="url(#paint22_linear_7752_42677)"
				d="M300.537 87.93H305.299V88.22800000000001H300.537z"
				transform="rotate(-110.969 300.537 87.93)"
			></path>
			<path
				fill="url(#paint23_linear_7752_42677)"
				d="M320.189 54.786H324.951V55.084H320.189z"
				transform="rotate(69.031 320.189 54.786)"
			></path>
			<path
				fill="url(#paint24_linear_7752_42677)"
				d="M356.718 66.4H361.48V66.69800000000001H356.718z"
				transform="rotate(-110.969 356.718 66.4)"
			></path>
			<circle
				cx="291.249"
				cy="63.543"
				r="2.775"
				fill="#7BC598"
				transform="rotate(159.031 291.249 63.543)"
			></circle>
			<circle
				cx="347.43"
				cy="42.012"
				r="2.775"
				fill="#7BC598"
				transform="rotate(159.031 347.43 42.012)"
			></circle>
			<path
				fill="url(#paint25_linear_7752_42677)"
				d="M292.08 65.558H296.842V65.85600000000001H292.08z"
				transform="rotate(69.031 292.08 65.558)"
			></path>
			<path
				fill="url(#paint26_linear_7752_42677)"
				d="M348.261 44.027H353.023V44.325H348.261z"
				transform="rotate(69.031 348.261 44.027)"
			></path>
			<path
				fill="url(#paint27_linear_7752_42677)"
				d="M308.301 77.712H312.646V78.048H308.301z"
				transform="rotate(-170.969 308.301 77.712)"
			></path>
			<path
				fill="url(#paint28_linear_7752_42677)"
				d="M336.372 66.954H340.71700000000004V67.28999999999999H336.372z"
				transform="rotate(-170.969 336.372 66.954)"
			></path>
			<path
				fill="url(#paint29_linear_7752_42677)"
				d="M0 0H4.345V0.336H0z"
				transform="scale(1 -1) rotate(50.969 236.775 294.154)"
			></path>
			<path
				fill="url(#paint30_linear_7752_42677)"
				d="M0 0H4.345V0.336H0z"
				transform="scale(1 -1) rotate(50.969 239.526 328.979)"
			></path>
			<rect
				width="33.254"
				height="33.254"
				x="345.882"
				fill="#F5CF6C"
				rx="4"
			></rect>
			<rect
				width="33.254"
				height="33.254"
				x="312.628"
				y="299.287"
				fill="#F5CF6C"
				rx="4"
			></rect>
			<circle
				cx="289.311"
				cy="277.394"
				r="12.425"
				fill="url(#paint31_linear_7752_42677)"
				transform="rotate(-45 289.311 277.394)"
			></circle>
			<circle
				cx="303.812"
				cy="257.351"
				r="5.904"
				fill="url(#paint32_linear_7752_42677)"
				transform="rotate(27.092 303.812 257.351)"
			></circle>
			<circle
				cx="285.572"
				cy="312.477"
				r="8.343"
				fill="url(#paint33_linear_7752_42677)"
				transform="rotate(90 285.572 312.477)"
			></circle>
			<path
				fill="url(#paint34_linear_7752_42677)"
				d="M285.838 304.662H302.20300000000003V305.291H285.838z"
				transform="rotate(-90 285.838 304.662)"
			></path>
			<path
				fill="url(#paint35_linear_7752_42677)"
				d="M293.722 265.462H301.109V266.091H293.722z"
				transform="rotate(-45 293.722 265.462)"
			></path>
			<path
				fill="#fff"
				d="M328.016 319.938c-.234-.468-.351-1.028-.351-1.679 0-.485.092-.911.276-1.279.184-.368.418-.677.702-.928a4.85 4.85 0 01.978-.676c.367-.184.718-.343 1.053-.477.785-.301 1.395-.668 1.83-1.103.434-.451.652-1.086.652-1.905 0-.852-.326-1.538-.978-2.056-.652-.535-1.588-.802-2.808-.802-.852 0-1.688.167-2.507.501a6.147 6.147 0 00-2.106 1.404l-1.203-1.579a7.623 7.623 0 012.632-1.68c1.02-.384 2.14-.576 3.36-.576.852 0 1.629.108 2.331.326.719.2 1.329.509 1.83.927.518.418.919.944 1.203 1.579.301.636.452 1.371.452 2.207 0 .768-.218 1.504-.652 2.206-.418.685-1.053 1.245-1.905 1.679-.385.201-.769.376-1.154.527-.367.15-.702.326-1.002.526a2.35 2.35 0 00-.702.702c-.184.284-.276.66-.276 1.128v1.028h-1.655zm.828 1.229c.534 0 .944.158 1.228.476.301.318.451.71.451 1.178 0 .485-.15.869-.451 1.153-.284.285-.694.427-1.228.427-.535 0-.945-.142-1.229-.427-.284-.284-.426-.668-.426-1.153 0-.468.142-.86.426-1.178.284-.318.694-.476 1.229-.476zM361.704 20.67c-.234-.468-.351-1.028-.351-1.68 0-.484.092-.91.276-1.278.183-.368.417-.677.702-.928.3-.267.626-.493.977-.677.368-.184.719-.342 1.053-.476.786-.301 1.396-.669 1.83-1.103.435-.451.652-1.087.652-1.905 0-.853-.326-1.538-.978-2.056-.651-.535-1.587-.802-2.807-.802-.853 0-1.688.167-2.507.501a6.125 6.125 0 00-2.106 1.404l-1.204-1.58a7.636 7.636 0 012.633-1.68c1.019-.384 2.139-.576 3.359-.576.852 0 1.63.109 2.332.326.718.2 1.328.51 1.83.928.518.418.919.944 1.203 1.58.301.634.451 1.37.451 2.205 0 .769-.217 1.504-.652 2.206-.417.686-1.052 1.245-1.905 1.68-.384.2-.769.376-1.153.526-.368.15-.702.326-1.003.527a2.347 2.347 0 00-.702.702c-.184.284-.276.66-.276 1.128v1.028h-1.654zm.827 1.228c.535 0 .944.16 1.229.477.3.317.451.71.451 1.178 0 .485-.151.869-.451 1.153-.285.284-.694.426-1.229.426-.535 0-.944-.142-1.228-.426-.284-.284-.426-.668-.426-1.153 0-.468.142-.86.426-1.178.284-.318.693-.477 1.228-.477z"
			></path>
			<ellipse
				cx="169.162"
				cy="177.16"
				fill="#A6BFF7"
				rx="18.848"
				ry="19.197"
				transform="rotate(169.026 169.162 177.16)"
			></ellipse>
			<circle
				cx="207.357"
				cy="186.57"
				r="11.406"
				fill="#F8CCA9"
				transform="rotate(-10.974 207.357 186.57)"
			></circle>
			<path
				fill="url(#paint36_linear_7752_42677)"
				d="M174.639 204.896H185.80800000000002V205.594H174.639z"
				transform="rotate(-100.974 174.639 204.896)"
			></path>
			<path
				fill="url(#paint37_linear_7752_42677)"
				d="M105.269 219.241H107.587V219.93900000000002H105.269z"
				transform="rotate(-95.093 105.269 219.241)"
			></path>
			<path
				fill="url(#paint38_linear_7752_42677)"
				d="M164.212 149.781H175.381V150.479H164.212z"
				transform="rotate(79.026 164.212 149.781)"
			></path>
			<path
				fill="url(#paint39_linear_7752_42677)"
				d="M243.854 191.474H255.02300000000002V192.172H243.854z"
				transform="rotate(-100.974 243.854 191.474)"
			></path>
			<path
				fill="url(#paint40_linear_7752_42677)"
				d="M233.427 136.358H242.265V137.056H233.427z"
				transform="rotate(79.026 233.427 136.358)"
			></path>
			<path
				fill="url(#paint41_linear_7752_42677)"
				d="M196.635 184.474H206.826V185.262H196.635z"
				transform="rotate(-160.974 196.635 184.474)"
			></path>
			<path
				fill="url(#paint42_linear_7752_42677)"
				d="M152.351 188.321H153.972V188.763H152.351z"
				transform="rotate(-40.974 152.351 188.321)"
			></path>
			<path
				fill="url(#paint43_linear_7752_42677)"
				d="M0 0H10.191V0.788H0z"
				transform="scale(1 -1) rotate(40.974 350.77 198.201)"
			></path>
			<circle
				cx="107.122"
				cy="225.42"
				r="6.508"
				fill="#7BC598"
				transform="rotate(-10.974 107.122 225.42)"
			></circle>
			<circle
				cx="175.737"
				cy="209.891"
				r="6.508"
				fill="#7BC598"
				transform="rotate(-10.974 175.737 209.891)"
			></circle>
			<circle
				cx="163.115"
				cy="144.788"
				r="6.508"
				fill="#7BC598"
				transform="rotate(169.026 163.115 144.788)"
			></circle>
			<circle
				cx="244.954"
				cy="196.47"
				r="6.508"
				fill="#7BC598"
				transform="rotate(-10.974 244.954 196.47)"
			></circle>
			<circle
				cx="232.328"
				cy="131.365"
				r="6.508"
				fill="#7BC598"
				transform="rotate(169.026 232.328 131.365)"
			></circle>
			<ellipse
				cx="238.376"
				cy="163.738"
				fill="#A6BFF7"
				rx="18.848"
				ry="19.197"
				transform="rotate(169.026 238.376 163.738)"
			></ellipse>
			<path
				fill="#CCDBFB"
				fillRule="evenodd"
				d="M249.342 304.58c9.871 19.453 32.314 30.027 54.224 24.156 25.147-6.738 40.071-32.586 33.333-57.733-3.182-11.873-10.624-21.468-20.24-27.579l.464-.442-3.14-1.137a47.234 47.234 0 00-13.565-4.914l-126.815-45.936 75.739 113.585zM341.036 119.017c20.795-7.727 35.606-27.71 35.606-51.141 0-30.132-24.491-54.558-54.702-54.558-24.73 0-45.628 16.367-52.397 38.832l-86.222 125.688 157.82-58.715-.105-.106z"
				clipRule="evenodd"
				opacity="0.3"
			></path>
			<path
				fill="#505F79"
				d="M188.232 310.153c-5.253 2.295-3.939-41.372-2.626-63.492 7.221-3.229 17.07-8.251 21.337 0 4.268 8.25.657 52.372 0 58.111-.656 5.739-.328 4.305-.985 5.381-.656 1.076-2.298 0-5.58 0-3.283 0-.985 0-1.642-1.076-.656-1.077 0-42.687-1.969-47.709-1.97-5.022-2.626 38.741-1.641 44.121.984 5.381-.329 4.305-.985 5.381-.526.861-4.158-.119-5.909-.717z"
			></path>
			<path
				fill="#505F79"
				d="M207.337 248.141c-.265.797-14.605 1.328-21.907 1.992-1.992 37.841 0 53.176 0 54.769 0 1.594.664 5.311 4.315 5.643 0-18.478-1.807-55.581-1.276-56.643.531-1.062 14-.443 20.196 0-.221-1.217-1.062-6.557-1.328-5.761z"
			></path>
			<path
				fill="#C1C7D0"
				d="M164.898 253.57c.797 0 3.43-2.213 4.647-3.32 1.231-1.062 2.284 1.107 2.656 2.324-1.217 1.106-3.917 3.718-4.979 5.311-1.328 1.992-.332-.664-1.66.996-1.327 1.659-1.991 1.328-3.319.664-1.328-.664-2.656-2.988-2.324-3.652.332-.663 3.983-2.323 4.979-2.323z"
			></path>
			<path
				fill="#1753E2"
				d="M213.028 243.612c-.266 1.593-3.873 2.213-5.643 2.323.221 1.328.531 4.116 0 4.647-.663.664-1.66.664-3.652.664-1.992 0-2.324-.332-9.294 0-5.577.266-8.741-.774-9.626-1.327.221-3.762.531-11.485 0-12.282-.664-.996-1.66 1.66-3.652 4.315-1.991 2.656-3.651 6.307-3.983 7.967-.332 1.659-1.327 2.655-2.987 3.319-1.328.531-4.536-3.098-5.975-4.979.111-.332.266-1.062 0-1.328-.332-.332 2.324-.332 1.992-1.991-.332-1.66 13.942-26.223 16.597-28.547 2.124-1.859 13.941.775 19.584 2.324 5.643 9.294 6.971 22.903 6.639 24.895z"
			></path>
			<path
				fill="#C1C7D0"
				d="M189.131 318.629v-7.966h3.652c.663 2.655 1.792 7.966.995 7.966h-4.647zM201.41 319.293v-9.374c.461-.6 1.729-1.658 3.112-1.081 1.728.721 1.037 1.442 1.383 2.163.276.577.115 5.288 0 7.571l-4.495.721z"
			></path>
			<path
				fill="#1D1D1D"
				d="M206.106 317.777c-.551-.862-3.216-.359-4.48 0-.46 1.555-1.241 4.953-.69 6.101.69 1.436 4.481.359 5.86 0 1.378-.359 0-5.025-.69-6.101zM189.414 317.777c.551-.862 3.217-.359 4.48 0 .46 1.555 1.241 4.953.69 6.101-.69 1.436-4.481.359-5.859 0-1.379-.359 0-5.025.689-6.101zM186.471 221.704c-1.859-2.39-.775-4.536 0-5.311l.664-1.328c0-.885-.133-3.186-.664-5.311-.664-2.655 1.992-4.647 2.656-7.634.664-2.987 7.303-2.655 8.63-1.992 1.328.664 1.66 3.652 2.988 6.639 1.062 2.39 1.106 5.643.995 6.971 1.328 1.659 3.984 5.311 3.984 6.638 0 1.66-.664 4.316-2.324 7.303-1.66 2.987-2.323 1.992-6.97 1.992h-.001c-4.647 0-4.647 0-5.642-1.328-.996-1.328-.332-2.988-.996-2.988-.664 0-.997-.663-3.32-3.651z"
			></path>
			<path
				fill="#C1C7D0"
				d="M87.413 196.347c0 1.663-.555 2.079-.832 2.079.665 1.663 3.326 1.802 4.573 1.663.14-.554.666-2.245 1.663-4.573 1.248-2.911 0-2.495-.831-4.158-.832-1.663-2.495-1.663-2.91-1.663-.416 0-1.248.832-1.663 2.495-.416 1.663 0 2.079 0 4.157z"
			></path>
			<path
				fill="#3C67F0"
				d="M59.558 222.124c-2.66 0-6.652.555-8.315.832-8.316 2.495-8.731 7.068-7.9 8.731.832 1.663 2.079 19.125 3.326 20.373 1.248 1.247 17.047 1.247 20.789 0 3.742-1.248-1.248-18.71-1.663-19.541-.416-.832 16.63-6.237 19.54-8.732 2.329-1.995 5.128-16.907 6.237-24.114-.832-.97-2.993-2.578-4.99-1.247-2.494 1.663-6.651 19.125-9.562 20.372-2.91 1.248-14.136 3.326-17.462 3.326z"
			></path>
			<path
				fill="#C1C7D0"
				d="M50.816 222.957l-.832-3.326c.693-4.296 2.578-12.889 4.574-12.889 2.494 0 4.573 3.326 5.82 4.989 1.248 1.663.416 4.99.416 6.237 0 .998-2.494 2.079-3.742 2.494v2.079l-6.236.416z"
			></path>
			<path
				fill="#253858"
				d="M51.72 214.487c.925-3.747 4.632-6.742 6.175-8.161-14.139-2.079-13.885 6.502-13.304 8.731.581 2.229 3.528 4.681 5.971 5.817 0-.568.231-2.64 1.157-6.387z"
			></path>
			<path
				fill="#253858"
				d="M53.323 212.977c-.998-.997.693-4.019 1.663-5.404-1.247 1.247-3.824 4.074-4.157 5.404-.416 1.664 1.663 5.821 2.494 6.653.831.831 4.99.831 6.653.415 1.33-.332 1.386-2.356 1.247-3.326-.415.278-1.33.832-1.663.832-.415 0-1.247-1.247-.831-2.079.332-.665 1.802-.831 2.494-.831v-.832c-.554-.139-1.829-.416-2.494-.416-.665 0-2.218 1.109-2.91 1.663a18.556 18.556 0 01-2.496-2.079z"
			></path>
			<path
				fill="#1753E2"
				d="M42.508 249.15c-.665-1.663.277-12.889.831-18.294l3.327 21.204v2.495c-1.11-1.109-3.493-3.742-4.158-5.405z"
			></path>
			<path
				fill="#253858"
				d="M49.099 287.059c-1.1-4.357-2.9-24.636-3.663-34.231 4.884 3.76 14.56 12.525 14.194 17.504-.366 4.979-.61 39.03-.916 52.904-1.831.259-5.769 1.245-6.868 0-1.374-1.556-1.374-30.731-2.747-36.177z"
			></path>
			<path
				fill="#505F79"
				d="M47.546 259.402c-3.066-3.416-2.129-6.341-1.277-7.377 4.826.518 15.67.466 22.142-.776 5.11 9.939 9.368 26.401 14.904 72.602h-5.536c-7.664-17.083-7.664-34.554-8.942-37.272-1.277-2.717-6.387-8.153-7.665-10.871-1.277-2.717-9.793-12.035-13.626-16.306z"
			></path>
			<path
				fill="#1D1D1D"
				d="M51.748 327.127v-5.045l6.237-.776 5.405 4.657v1.164H51.748zM77.436 326.931v-5.405l5.405-.831 9.563 4.989v1.247H77.436z"
			></path>
			<defs>
				<linearGradient
					id="paint0_linear_7752_42677"
					x1="131.281"
					x2="125.911"
					y1="209.209"
					y2="209.209"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_7752_42677"
					x1="112.58"
					x2="107.21"
					y1="190.96"
					y2="190.96"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint2_linear_7752_42677"
					x1="154.11"
					x2="148.74"
					y1="185.598"
					y2="185.598"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint3_linear_7752_42677"
					x1="135.409"
					x2="130.039"
					y1="167.35"
					y2="167.35"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint4_linear_7752_42677"
					x1="133.757"
					x2="128.857"
					y1="195.562"
					y2="195.562"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#FAD8BD"></stop>
				</linearGradient>
				<linearGradient
					id="paint5_linear_7752_42677"
					x1="4.827"
					x2="-0.073"
					y1="0.193"
					y2="0.193"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#FAD8BD"></stop>
				</linearGradient>
				<linearGradient
					id="paint6_linear_7752_42677"
					x1="4.827"
					x2="-0.073"
					y1="0.193"
					y2="0.193"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#FAD8BD"></stop>
				</linearGradient>
				<linearGradient
					id="paint7_linear_7752_42677"
					x1="82.428"
					x2="77.058"
					y1="199.966"
					y2="199.966"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint8_linear_7752_42677"
					x1="63.727"
					x2="58.356"
					y1="181.718"
					y2="181.718"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint9_linear_7752_42677"
					x1="105.256"
					x2="99.886"
					y1="176.355"
					y2="176.355"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint10_linear_7752_42677"
					x1="86.555"
					x2="81.185"
					y1="158.107"
					y2="158.107"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint11_linear_7752_42677"
					x1="84.904"
					x2="80.004"
					y1="186.319"
					y2="186.319"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#FAD8BD"></stop>
				</linearGradient>
				<linearGradient
					id="paint12_linear_7752_42677"
					x1="4.827"
					x2="-0.073"
					y1="0.193"
					y2="0.193"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#FAD8BD"></stop>
				</linearGradient>
				<linearGradient
					id="paint13_linear_7752_42677"
					x1="4.827"
					x2="-0.073"
					y1="0.193"
					y2="0.193"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#FAD8BD"></stop>
				</linearGradient>
				<linearGradient
					id="paint14_linear_7752_42677"
					x1="140.936"
					x2="135.566"
					y1="136.783"
					y2="136.783"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint15_linear_7752_42677"
					x1="118.076"
					x2="112.706"
					y1="160.426"
					y2="160.426"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint16_linear_7752_42677"
					x1="122.235"
					x2="116.865"
					y1="118.535"
					y2="118.535"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint17_linear_7752_42677"
					x1="99.374"
					x2="94.004"
					y1="142.178"
					y2="142.178"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint18_linear_7752_42677"
					x1="120.583"
					x2="115.683"
					y1="146.746"
					y2="146.746"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#FAD8BD"></stop>
				</linearGradient>
				<linearGradient
					id="paint19_linear_7752_42677"
					x1="143.412"
					x2="138.512"
					y1="123.136"
					y2="123.136"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#FAD8BD"></stop>
				</linearGradient>
				<linearGradient
					id="paint20_linear_7752_42677"
					x1="4.827"
					x2="-0.073"
					y1="0.193"
					y2="0.193"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#FAD8BD"></stop>
				</linearGradient>
				<linearGradient
					id="paint21_linear_7752_42677"
					x1="333.489"
					x2="328.574"
					y1="77.314"
					y2="77.314"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint22_linear_7752_42677"
					x1="305.38"
					x2="300.464"
					y1="88.087"
					y2="88.087"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint23_linear_7752_42677"
					x1="325.032"
					x2="320.117"
					y1="54.943"
					y2="54.943"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint24_linear_7752_42677"
					x1="361.56"
					x2="356.645"
					y1="66.556"
					y2="66.556"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint25_linear_7752_42677"
					x1="296.922"
					x2="292.007"
					y1="65.715"
					y2="65.715"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint26_linear_7752_42677"
					x1="353.103"
					x2="348.188"
					y1="44.184"
					y2="44.184"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
				</linearGradient>
				<linearGradient
					id="paint27_linear_7752_42677"
					x1="312.719"
					x2="308.234"
					y1="77.889"
					y2="77.889"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#FAD8BD"></stop>
				</linearGradient>
				<linearGradient
					id="paint28_linear_7752_42677"
					x1="340.791"
					x2="336.305"
					y1="67.131"
					y2="67.131"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#FAD8BD"></stop>
				</linearGradient>
				<linearGradient
					id="paint29_linear_7752_42677"
					x1="4.419"
					x2="-0.066"
					y1="0.177"
					y2="0.177"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#FAD8BD"></stop>
				</linearGradient>
				<linearGradient
					id="paint30_linear_7752_42677"
					x1="4.419"
					x2="-0.066"
					y1="0.177"
					y2="0.177"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#FAD8BD"></stop>
				</linearGradient>
				<linearGradient
					id="paint31_linear_7752_42677"
					x1="279.182"
					x2="296.442"
					y1="283.284"
					y2="274.423"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#305CBD"></stop>
					<stop offset="1" stopColor="#1753E2" stopOpacity="0.04"></stop>
				</linearGradient>
				<linearGradient
					id="paint32_linear_7752_42677"
					x1="299.294"
					x2="310.189"
					y1="261.099"
					y2="253.951"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F8CCA9" stopOpacity="0.78"></stop>
					<stop offset="1" stopColor="#F8CCA9" stopOpacity="0"></stop>
				</linearGradient>
				<linearGradient
					id="paint33_linear_7752_42677"
					x1="279.187"
					x2="290.36"
					y1="317.773"
					y2="310.482"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F8CCA9"></stop>
					<stop offset="1" stopColor="#FCEADC" stopOpacity="0.2"></stop>
				</linearGradient>
				<linearGradient
					id="paint34_linear_7752_42677"
					x1="302.48"
					x2="285.588"
					y1="304.993"
					y2="304.993"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#305CBD"></stop>
					<stop offset="1" stopColor="#F8CCA9"></stop>
				</linearGradient>
				<linearGradient
					id="paint35_linear_7752_42677"
					x1="301.233"
					x2="293.609"
					y1="265.794"
					y2="265.794"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#F8CCA9"></stop>
					<stop offset="1" stopColor="#537AF2"></stop>
				</linearGradient>
				<linearGradient
					id="paint36_linear_7752_42677"
					x1="185.997"
					x2="174.468"
					y1="205.264"
					y2="205.264"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#7BC598"></stop>
				</linearGradient>
				<linearGradient
					id="paint37_linear_7752_42677"
					x1="107.626"
					x2="105.233"
					y1="219.609"
					y2="219.609"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#7BC598"></stop>
				</linearGradient>
				<linearGradient
					id="paint38_linear_7752_42677"
					x1="175.57"
					x2="164.041"
					y1="150.149"
					y2="150.149"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#7BC598"></stop>
				</linearGradient>
				<linearGradient
					id="paint39_linear_7752_42677"
					x1="255.212"
					x2="243.684"
					y1="191.842"
					y2="191.842"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#7BC598"></stop>
				</linearGradient>
				<linearGradient
					id="paint40_linear_7752_42677"
					x1="242.414"
					x2="233.292"
					y1="136.726"
					y2="136.726"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#D2F9E1"></stop>
					<stop offset="1" stopColor="#7BC598"></stop>
				</linearGradient>
				<linearGradient
					id="paint41_linear_7752_42677"
					x1="206.998"
					x2="196.479"
					y1="184.889"
					y2="184.889"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#F8CCA9"></stop>
				</linearGradient>
				<linearGradient
					id="paint42_linear_7752_42677"
					x1="153.999"
					x2="152.326"
					y1="188.554"
					y2="188.554"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#F8CCA9"></stop>
				</linearGradient>
				<linearGradient
					id="paint43_linear_7752_42677"
					x1="10.363"
					x2="-0.156"
					y1="0.415"
					y2="0.415"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#A6BFF7"></stop>
					<stop offset="1" stopColor="#F8CCA9"></stop>
				</linearGradient>
			</defs>
		</svg>
	)
}
