import React, { useMemo, useState } from "react"
import { Card, CardProps } from "./card"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Tabs } from "./tabs"
import { ChevronLeft, ChevronRight } from "react-feather"
import { AiForwardPredictionIllustration } from "components/library/illustrations/lab/ai-forward-prediction";
import { AiInversePredictionIllustration } from "components/library/illustrations/lab/inverse-prediction";
import { DataSummarySmallIllustration } from "components/library/illustrations/lab/data-summary-small";
import { ExplainableAIIllustration } from "components/library/illustrations/lab/explainable-ai";

const benefits: CardProps[] = [
	{
		heading: "products-ai-page.features-of-ai-engine.slideshow.1.heading",
		desc: [
			"products-ai-page.features-of-ai-engine.slideshow.1.desc.1",
			"products-ai-page.features-of-ai-engine.slideshow.1.desc.2",
			"products-ai-page.features-of-ai-engine.slideshow.1.desc.3",
		],
		image: AiForwardPredictionIllustration,
	},
	{
		heading: "products-ai-page.features-of-ai-engine.slideshow.2.heading",
		desc: [
			"products-ai-page.features-of-ai-engine.slideshow.2.desc.1",
			"products-ai-page.features-of-ai-engine.slideshow.2.desc.2",
			"products-ai-page.features-of-ai-engine.slideshow.2.desc.3",
		],
		image: AiInversePredictionIllustration,
	},
	{
		heading: "products-ai-page.features-of-ai-engine.slideshow.3.heading",
		desc: [
			"products-ai-page.features-of-ai-engine.slideshow.3.desc.1",
			"products-ai-page.features-of-ai-engine.slideshow.3.desc.2",
			"products-ai-page.features-of-ai-engine.slideshow.3.desc.3",
		],
		image: DataSummarySmallIllustration,
	},
	{
		heading: "products-ai-page.features-of-ai-engine.slideshow.4.heading",
		desc: [
			"products-ai-page.features-of-ai-engine.slideshow.4.desc.1",
			"products-ai-page.features-of-ai-engine.slideshow.4.desc.2",
			"products-ai-page.features-of-ai-engine.slideshow.4.desc.3",
		],
		image: ExplainableAIIllustration,
	},
]

export const Slideshow = () => {
	const [slide, setSlide] = useState(0)

	const slides = useMemo(() => {
		return benefits.map((benefit) => (
			<Card key={benefit.heading} {...benefit} />
		))
	}, [])

	return (
		<section className="flex flex-col gap-4 sm:gap-6 w-full items-center">
			<Tabs slide={slide} setSlide={setSlide} />

			<div className="flex flex-col gap-8 w-[95%] relative">
				<button
					onClick={() => setSlide(slide - 1)}
					disabled={slide === 0}
					className="h-full absolute disabled:text-gray-400 disabled:cursor-not-allowed flex items-center left-[-4%] z-[10]"
				>
					<ChevronLeft size={32} />
				</button>

				<Carousel
					onChange={setSlide}
					selectedItem={slide}
					infiniteLoop={true}
					showArrows={false}
					showStatus={false}
					showIndicators={false}
					interval={7000}
				>
					{slides.map(slide => slide)}
				</Carousel>

				<button
					onClick={() => setSlide(slide + 1)}
					disabled={slide === benefits.length - 1}
					className="h-full absolute flex disabled:text-gray-400 disabled:cursor-not-allowed items-center right-[-4%] z-[10]"
				>
					<ChevronRight size={32} />
				</button>
			</div>
		</section>
	)
}
