import React from "react"
import { useTranslation } from "react-i18next"
import { ExperimentIllustration } from "components/library/illustrations/experiment-illustration";
import { RequestADemoPrimaryCta } from "components/common/request-a-demo-cta/primary";

export const Hero = () => {
	const { t } = useTranslation()

	return (
		<section className="flex justify-between gap-12 items-end">
			<div className="flex flex-col gap-6">
				<h1 className="text-4xl gradient-text max-w-max sm:text-5xl md:text-6xl">
					{t("products-ai-page.hero.title")}
				</h1>
				<p className="sm:text-lg sm:max-w-xl md:text-2xl">
					{t("products-ai-page.hero.desc")}
				</p>
				<div className="flex flex-col sm:flex-row gap-4">
					<RequestADemoPrimaryCta />
				</div>
			</div>

			<ExperimentIllustration className="hidden lg:block shrink-0 w-[35%]" />
		</section>
	)
}
