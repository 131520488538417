import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { TranslationKey } from "types/translation"
import { Illustration } from "types/components"

type P = {
	heading: TranslationKey
	desc: TranslationKey[]
	image: Illustration
}

export type CardProps = P

export const Card = ({ desc, heading, image }: P) => {
	const { t } = useTranslation()
	const Image = image

	return (
		<div className="p-6 sm:p-10 bg-[#F3F6FA] flex flex-col gap-10 w-full sm:gap-6 h-full">
			<div className="flex flex-col gap-6 md:gap-10 md:col-span-2">
				<h3 className="text-xl sm:text-2xl md:text-4xl">
					{t(heading) as string}
				</h3>
				<p>{t(desc[0]) as string}</p>
			</div>

			<div className="flex flex-col sm:flex-row-reverse gap-6">
				<Image className="w-full sm:w-[40%]" />
				<p className="flex flex-col gap-4">
					{desc.slice(1).map((d) => {
						return <span className="max-w-3xl">{t(d) as string}</span>
					})}
				</p>
			</div>
		</div>
	)
}
